import SheaConditioner from './images/Shea_Conditioner.png'
import VirtueConditioner from './images/Virtue_Conditioner.png'
import MonpureConditioner from './images/Monpure_Conditioner.png'

export const WeeklyHairCareRoutine = {
    id: 3,
    name: 'Weekly Hair Care Routine',
    description: 'Maintain healthy hair with regular care and nourishment.',
    canBeShortened: false,
    routines: [
        {
            name: 'Deep Conditioning (Once a Week)',
            description: 'Restore moisture and repair damage with a deep conditioning treatment.',
            steps: [
                'Shampoo your hair as usual.',
                'Apply a deep conditioning mask from mid-length to ends.',
                'Leave it on for the recommended time (usually 10-20 minutes).',
                'Rinse thoroughly with cool water.',
            ],
            feedback: 'Using cool water helps seal the hair cuticle, locking in moisture.',
            products: {
                beginner: [
                    {
                        id: 301,
                        name: 'SheaMoisture Intensive Hydration Hair Masque',
                        description: 'An affordable deep conditioning masque that provides intensive hydration and restores moisture to dry, damaged hair.',
                        link: 'https://amzn.to/3XTBkfE',
                        image: SheaConditioner
                    },
                ],
                advanced: [
                    {
                        id: 302,
                        name: 'Virtue Restorative Hair Mask',
                        description: 'A mid-range restorative mask that repairs damaged strands and strengthens hair from root to tip.',
                        link: 'https://amzn.to/47T2fwJ',
                        image: VirtueConditioner
                    },
                ],
                luxury: [
                    {
                        id: 303,
                        name: 'Monpure Nourish and Stimulate Scalp Mask',
                        description: 'A luxury scalp mask that nourishes the scalp and stimulates healthy hair growth.',
                        link: 'https://amzn.to/3TZ7zZw',
                        image: MonpureConditioner
                    },
                ],
            },
        },
        {
            name: 'Scalp Massage',
            description: 'Stimulate blood flow to the scalp to promote hair growth.',
            steps: [
                'Use your fingertips to gently massage your scalp in circular motions.',
                'Do this for about 5 minutes, either with or without oil.',
            ],
            feedback: 'Regular scalp massages can help reduce stress and improve hair health.',
            products: {
                beginner: [],
                advanced: [],
                luxury: [],
            },
        },
    ],
}