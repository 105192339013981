import React, { useState, useMemo } from 'react';
import ProgressBar from '../../../../General/ProgressBar';
import colors from '../../../../styles/colors';
import BellCurve from '../../../../General/BellCurve';
import { calculateScorePercentile } from '../../utils';
import UnlockingModal from '../../UnlockingModal';
import LoginModal from '../../../../Unauthorized/components/LoginModal';
import FrontTraitsToggle from '../../FrontTraitsToggle';
import FrontRatiosToggle from '../../FrontRatiosToggle';

function ComparisonLockedComponent({ data, imagePath1, imagePath2, onUnlockConfirm }) {
  const [visibleFeatures, setVisibleFeatures] = useState({});
  const [toggleView, setToggleView] = useState('traits');
  const [isUnlockingModalOpen, setIsUnlockingModalOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const userScore1 = useMemo(() => Math.ceil(data.frontResult1.score * 10), [data.frontResult1.score]);
  const userScore2 = useMemo(() => Math.ceil(data.frontResult2.score * 10), [data.frontResult2.score]);

  const userScorePercentile1 = useMemo(() => calculateScorePercentile(userScore1 / 10), [userScore1]);
  const userScorePercentile2 = useMemo(() => calculateScorePercentile(userScore2 / 10), [userScore2]);

  const handleBlurClick = () => {
    setIsUnlockingModalOpen(true);
    document.body.classList.add('modal-open');
  };

  const closeUnlockingModal = () => {
    setIsUnlockingModalOpen(false);
    document.body.classList.remove('modal-open');
  };

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
    document.body.classList.remove('modal-open');
  };

  const toggleFeature = (feature) => {
    setVisibleFeatures((prev) => ({
      ...prev,
      [feature]: !prev[feature],
    }));
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
        {imagePath1 && (
          <div style={{ flex: '1 1 30%', marginTop: '30px', display: 'flex', flexDirection: 'column' }}>
            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
              <strong style={{ fontSize: '24px', color: 'black' }}>Score: {userScore1} </strong>
              <ProgressBar percent={userScorePercentile1} style={{ width: '100%' }} />
              <BellCurve score={userScorePercentile1} style={{ width: '100%' }} />
            </div>
            <div style={{ padding: 30 }}>
              <img src={imagePath1} alt="Processed 1" style={{ 
                width: '100%', 
                border: `12px solid ${colors.primary}`, 
                borderRadius: '25px'
              }} />
            </div>
          </div>
        )}
        
        <div style={{ flex: '1 1 33%', marginTop: '30px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center' }}>
          <div style={{ width: '100%', maxWidth: '400px', marginTop: 'auto' }}>
            <div style={{ height: '100%', border: '2px solid gray', borderRadius: '10px', padding: '10px', filter: 'blur(3px)', cursor: 'pointer' }} onClick={handleBlurClick}>
              {toggleView === 'traits' ? (
                <FrontTraitsToggle visibleFeatures={visibleFeatures} toggleFeature={toggleFeature} />
              ) : (
                <FrontRatiosToggle visibleFeatures={visibleFeatures} toggleFeature={toggleFeature} />
              )}
            </div>
          </div>
        </div>

        {imagePath2 && (
          <div style={{ flex: '1 1 30%', marginTop: '30px', display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
              <strong style={{ fontSize: '24px', color: 'black' }}>Score: {userScore2} </strong>
              <ProgressBar percent={userScorePercentile2} style={{ width: '100%' }} />
              <BellCurve score={userScorePercentile2} style={{ width: '100%' }} />
            </div>
            <div style={{ padding: 30 }}>
              <img src={imagePath2} alt="Processed 2" style={{ 
                width: '100%', 
                border: `12px solid ${colors.primary}`, 
                borderRadius: '25px'
              }} />
            </div>
          </div>
        )}
      </div>
      {isLoginModalOpen || isUnlockingModalOpen ? <div style={backdropStyles}></div> : null}
      <UnlockingModal 
        isOpen={isUnlockingModalOpen} 
        onClose={closeUnlockingModal} 
        onConfirm={() => {
          closeUnlockingModal();
          onUnlockConfirm();
        }} 
      />
      <LoginModal 
        isOpen={isLoginModalOpen} 
        onClose={closeLoginModal} 
        onSignUpClick={() => {
          closeLoginModal();
          setIsUnlockingModalOpen(true);
          document.body.classList.add('modal-open');
        }} 
      />
    </div>
  );
}

const backdropStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 998, 
};

export default ComparisonLockedComponent;
