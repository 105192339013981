// src/navigation/AuthRoute.js
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { isTestingEmail } from '../utils/authUtils'; // Import the helper function

const AuthRoute = ({ component: Component, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [isVerified, setIsVerified] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
      // Bypass email verification if email ends with @testing.com
      setIsVerified(user ? (user.emailVerified || isTestingEmail(user)) : false);
    });

    return () => unsubscribe();
  }, []);

  if (isAuthenticated === null || isVerified === null) {
    return <div>Loading...</div>; // or a loading spinner
  }

  return isAuthenticated && isVerified ? <Component {...rest} /> : <Navigate to="/login" />;
};

export default AuthRoute;
