import AmazonMat from './images/Amazon_Mat.png'

export const ExerciseRoutine = {
    id: 4,
    name: 'Exercise Routine',
    description: 'Incorporate physical activity to boost circulation and overall well-being.',
    canBeShortened: false,
    routines: [
        {
            name: 'Morning Stretching',
            description:
                'Start your day with stretches to improve flexibility and reduce muscle tension.',
            steps: [
                'Begin with neck and shoulder rolls.',
                'Perform gentle back stretches like cat-cow poses.',
                'Stretch your arms and legs with basic yoga poses.',
                'Hold each stretch for about 15-30 seconds.',
            ],
            feedback:
                'Stretching improves blood flow and prepares your body for the day.',
            products: {
            },
        },
        {
            name: 'Cardio Exercise (3-4 times a week)',
            description:
                'Engage in activities that raise your heart rate.',
            steps: [
                'Choose an activity like brisk walking, jogging, or cycling.',
                'Aim for at least 30 minutes per session.',
                'Maintain a moderate intensity where you can still hold a conversation.',
            ],
            feedback:
                'Regular cardio improves cardiovascular health and aids in weight management.',
            products: {
                general: [ // Added General category
                    {
                        id: 1302, // Unique ID for the product
                        name: 'Amazon Basics Exercise Yoga Mat',
                        description: 'Enhance your stretching and yoga sessions with the Amazon Basics Exercise Yoga Mat, featuring a non-slip surface and comfortable cushioning.',
                        image: AmazonMat,
                        link: 'https://amzn.to/3YaRgeS',
                    },
                ],
            },
        },
    ],
};
