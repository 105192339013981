import EnerboneBottle from './images/Enerbone_Bottle.png'

export const HydrationRoutine = {
    id: 6,
    name: 'Hydration Routine',
    description: 'Maintain your body’s hydration levels throughout the day for optimal health.',
    canBeShortened: false,
    routines: [
        {
            name: 'Morning Hydration',
            description: 'Start your day by hydrating your body.',
            steps: [
                'Drink a glass of water immediately upon waking up.',
                'Add a slice of lemon for extra flavor and a boost of vitamin C.',
            ],
            feedback: 'Hydrating first thing in the morning jump-starts your metabolism and helps flush out toxins.',
            products: {
            },
        },
        {
            name: 'Track Your Water Intake',
            description: 'Keep track of your daily water consumption to ensure proper hydration.',
            steps: [
                'Aim to drink at least 8 glasses of water throughout the day.',
                'Use a water tracker app or a marked water bottle to monitor your intake.',
            ],
            feedback: 'Proper hydration is essential for maintaining skin elasticity, energy levels, and overall health.',
            products: {
                general: [ // Added General category
                    {
                        id: 1402, // Unique ID for the product
                        name: 'Enerbone Water Bottle',
                        description: 'Stay hydrated with the Enerbone Water Bottle, featuring a sleek design, durable materials, and a convenient carry handle.',
                        image: EnerboneBottle,
                        link: 'https://amzn.to/4dCtZXN',
                    },
                ],
            },
        },
    ],
};
