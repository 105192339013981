// src/components/Authorized/components/SearchModal.js

import React, { useEffect, useRef, useState } from 'react';
import {
  InstantSearch,
  SearchBox,
  Hits,
  Highlight,
  useInstantSearch,
  useSearchBox,
  usePagination,
  Configure,
} from 'react-instantsearch';
import searchClient from '../../../algoliaClient';
import styles from '../../styles/SearchModal.module.css';

// Import Firebase modules
import { auth, firestore } from '../../../firebaseConfig';
import { doc, getDoc, setDoc } from 'firebase/firestore';

// Import the ingredient data
import cleanedIngredients from '../../../assets/data/cleaned_ingredients.json';

// Utility functions
const formatPropertyName = (str) => {
  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const getClassForValue = (value) => {
  return `color${value}`;
};

const getSafetyClass = (decision) => {
  if (decision.includes('Safe') && !decision.includes('hazard')) {
    return 'safetySafe';
  } else if (decision.includes('Safe - Low hazard')) {
    return 'safetyLightgreen';
  } else if (
    decision.includes('Safe - Moderate hazard') ||
    decision.includes('Fair') ||
    decision.includes('Limited')
  ) {
    return 'safetyModerate';
  } else if (decision.includes('Moderate hazard')) {
    return 'safetyWarning';
  } else if (decision.includes('Unsafe') || decision.includes('High hazard')) {
    return 'safetyUnsafe';
  } else {
    return 'safetyUnknown';
  }
};

// Create a mapping from ingredient titles to ingredient data
const titleToIngredientMap = new Map();
cleanedIngredients.forEach((ingredient) => {
  titleToIngredientMap.set(ingredient.title.toLowerCase(), ingredient);
});

function SearchModal({ isOpen, onClose }) {
  const modalRef = useRef(null);

  useEffect(() => {
    if (isOpen && modalRef.current) {
      modalRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape' && isOpen) {
        onClose();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [isOpen, onClose]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className={styles.overlay}
      onClick={() => {
        onClose();
      }}
      role="dialog"
      aria-modal="true"
      aria-labelledby="search-modal-title"
    >
      <div
        className={styles.modal}
        onClick={(e) => {
          e.stopPropagation();
        }}
        tabIndex="-1"
        ref={modalRef}
      >
        <button
          className={styles.closeButton}
          onClick={() => {
            onClose();
          }}
          aria-label="Close Search Modal"
        >
          &times;
        </button>
        <h2 id="search-modal-title" className={styles.title}>
          Search Beauty Products
        </h2>
        <InstantSearch indexName="finished_products_dataset" searchClient={searchClient}>
          <Configure
            advancedSyntax={true}
            attributesToRetrieve={[
              'title',
              'brand',
              'primary_image',
              'matched_ingredients',
              'product_type',
              'specific_product_type',
              'price',
              'asin',
            ]}
          />
          <SearchBox
            translations={{
              placeholder: 'Search for products...',
            }}
            className={styles.searchBox}
          />
          <Results />
        </InstantSearch>
      </div>
    </div>
  );
}

function Results() {
  const { results } = useInstantSearch();
  const { query } = useSearchBox();

  const hasSearchStarted = query && query.trim() !== '';
  const hasResults = results && results.nbHits > 0;

  if (!hasSearchStarted) {
    return <div className={styles.noResults}>Start typing to search for products...</div>;
  }

  if (hasSearchStarted && !hasResults) {
    return <div className={styles.noResults}>No products found.</div>;
  }

  return (
    <>
      <Hits hitComponent={Hit} className={styles.hitsList} />
      <CustomPagination />
    </>
  );
}

function CustomPagination() {
  const { currentRefinement, nbPages, refine, isFirstPage, isLastPage } = usePagination();

  if (nbPages <= 1) return null;

  const maxPageButtons = 10;
  let startPage = Math.max(1, currentRefinement + 1 - Math.floor(maxPageButtons / 2));
  let endPage = startPage + maxPageButtons - 1;

  if (endPage > nbPages) {
    endPage = nbPages;
    startPage = Math.max(1, endPage - maxPageButtons + 1);
  }

  const pages = [];
  for (let i = startPage; i <= endPage; i++) {
    pages.push(
      <button
        key={i}
        className={`${styles.pageButton} ${currentRefinement + 1 === i ? styles.activePage : ''}`}
        onClick={() => refine(i - 1)}
        aria-label={`Page ${i}`}
      >
        {i}
      </button>
    );
  }

  return (
    <div className={styles.paginationContainer}>
      <button
        className={`${styles.navButton} ${isFirstPage ? styles.disabled : ''}`}
        onClick={() => refine(0)}
        disabled={isFirstPage}
        aria-label="First Page"
      >
        &#171;
      </button>

      <button
        className={`${styles.navButton} ${isFirstPage ? styles.disabled : ''}`}
        onClick={() => refine(currentRefinement - 1)}
        disabled={isFirstPage}
        aria-label="Previous Page"
      >
        &#8249;
      </button>

      {pages}

      <button
        className={`${styles.navButton} ${isLastPage ? styles.disabled : ''}`}
        onClick={() => refine(currentRefinement + 1)}
        disabled={isLastPage}
        aria-label="Next Page"
      >
        &#8250;
      </button>

      <button
        className={`${styles.navButton} ${isLastPage ? styles.disabled : ''}`}
        onClick={() => refine(nbPages - 1)}
        disabled={isLastPage}
        aria-label="Last Page"
      >
        &#187;
      </button>
    </div>
  );
}

function Hit({ hit }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showResultModal, setShowResultModal] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  const [resultSuccess, setResultSuccess] = useState(false);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const ingredientsList = hit.matched_ingredients
    ? hit.matched_ingredients.split(',').map((ingredient) => ingredient.trim())
    : [];

  const ingredientDetails = ingredientsList.map((ingredientName) => {
    const ingredientData = titleToIngredientMap.get(ingredientName.toLowerCase());
    return {
      name: ingredientName,
      data: ingredientData,
    };
  });

  const handleAddProductClick = () => {
    setShowConfirmModal(true);
  };

  const handleConfirmAdd = async () => {
    setShowConfirmModal(false);
    const user = auth.currentUser;
    if (!user) {
      setResultMessage('Please sign in to save products.');
      setResultSuccess(false);
      setShowResultModal(true);
      return;
    }

    try {
      const userDataDocRef = doc(firestore, 'users', user.uid, 'UserData', 'currentBeautyRoutine');
      const userDataDocSnap = await getDoc(userDataDocRef);
      const existingProducts = userDataDocSnap.exists() ? userDataDocSnap.data().products || [] : [];

      const alreadySaved = existingProducts.some((product) => product.id === hit.objectID);
      if (alreadySaved) {
        setResultMessage('Product is already in your beauty routine.');
        setResultSuccess(false);
        setShowResultModal(true);
        return;
      }

      await setDoc(
        userDataDocRef,
        {
          products: [
            ...existingProducts,
            {
              id: hit.objectID,
              title: hit.title,
              brand: hit.brand,
              primary_image: hit.primary_image,
              matched_ingredients: hit.matched_ingredients,
              product_type: hit.product_type,
              specific_product_type: hit.specific_product_type,
              price: hit.price,
              asin: hit.asin,
            },
          ],
        },
        { merge: true }
      );

      setResultMessage('Product added to your beauty routine!');
      setResultSuccess(true);
      setShowResultModal(true);
    } catch (error) {
      setResultMessage('Failed to add product to your beauty routine. Please try again.');
      setResultSuccess(false);
      setShowResultModal(true);
    }
  };

  const handleCancelAdd = () => {
    setShowConfirmModal(false);
  };

  const closeResultModal = () => {
    setShowResultModal(false);
  };

  // Generate Amazon affiliate link
  const associateId = 'goldenratio08-20';
  const amazonLink = hit.asin
    ? `http://www.amazon.com/dp/${hit.asin}/ref=nosim?tag=${associateId}`
    : null;

  return (
    <>
      <li className={styles.hit}>
        {hit.primary_image && (
          <img
            src={hit.primary_image}
            alt={hit.title}
            className={styles.productImage}
            onError={(e) => {
              e.target.style.display = 'none';
            }}
          />
        )}
        <div className={styles.productInfo}>
          <div className={styles.productHeader}>
            <h3
              className={styles.productTitle}
              onClick={toggleExpand}
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  toggleExpand();
                }
              }}
              tabIndex="0"
              role="button"
              aria-expanded={isExpanded}
              aria-label={`Toggle ingredients for ${hit.title}`}
            >
              <Highlight attribute="title" hit={hit} />
            </h3>
            <button
              onClick={handleAddProductClick}
              className={styles.addButton}
              aria-label="Add product to your beauty routine"
            >
              +
            </button>
          </div>
          <p className={styles.productBrand}>
            <strong>Brand:</strong> <Highlight attribute="brand" hit={hit} />
          </p>
          {hit.price && (
            <p className={styles.productPrice}>
              <strong>Price:</strong> ${hit.price}
            </p>
          )}
          {amazonLink && (
            <a
              href={amazonLink}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.buyNowButton}
              aria-label={`Buy ${hit.title} on Amazon`}
            >
              Buy Now
            </a>
          )}
          {isExpanded && (
            <div className={styles.ingredients}>
              <strong>Ingredients:</strong>
              {ingredientDetails.length > 0 ? (
                <div className={styles.ingredientButtons}>
                  {ingredientDetails.map((ingredient, index) => (
                    <button
                      key={index}
                      className={`${styles.ingredientButton} ${
                        styles[
                          ingredient.data
                            ? getSafetyClass(ingredient.data.ewg?.decision || '')
                            : 'safetyUnknown'
                        ]
                      }`}
                      onClick={() => ingredient.data && setSelectedIngredient(ingredient.data)}
                    >
                      {ingredient.name}
                    </button>
                  ))}
                </div>
              ) : (
                <p>Not available.</p>
              )}
            </div>
          )}
        </div>

        {/* Confirmation Modal */}
        {showConfirmModal && (
          <div
            className={styles.overlay}
            onClick={handleCancelAdd}
            role="dialog"
            aria-modal="true"
            aria-labelledby="confirm-modal-title"
          >
            <div
              className={styles.confirmModal}
              onClick={(e) => e.stopPropagation()}
              tabIndex="-1"
            >
              <h3 id="confirm-modal-title" className={styles.confirmTitle}>
                Confirm Addition
              </h3>
              <p>
                Are you sure you want to add <strong>{hit.title}</strong> to your beauty routine?
              </p>
              <div className={styles.modalButtons}>
                <button
                  onClick={handleConfirmAdd}
                  className={`${styles.modalButton} ${styles.confirmButton}`}
                >
                  Confirm
                </button>
                <button
                  onClick={handleCancelAdd}
                  className={`${styles.modalButton} ${styles.cancelButton}`}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Result Modal */}
        {showResultModal && (
          <div
            className={styles.overlay}
            onClick={closeResultModal}
            role="dialog"
            aria-modal="true"
            aria-labelledby="result-modal-title"
          >
            <div className={styles.resultModal} onClick={(e) => e.stopPropagation()} tabIndex="-1">
              <h3 id="result-modal-title" className={styles.resultTitle}>
                {resultSuccess ? 'Success' : 'Error'}
              </h3>
              <p>{resultMessage}</p>
              <button onClick={closeResultModal} className={styles.closeResultButton}>
                Close
              </button>
            </div>
          </div>
        )}

        {/* Ingredient Detail Modal */}
        {selectedIngredient && (
          <div className={styles.ingredientModal} onClick={() => setSelectedIngredient(null)}>
            <div className={styles.ingredientContent} onClick={(e) => e.stopPropagation()}>
              <h2>{selectedIngredient.title}</h2>
              {selectedIngredient.ewg && (
                <>
                  <h3>EWG Decision:</h3>
                  <p>{selectedIngredient.ewg.decision}</p>
                </>
              )}
              {selectedIngredient.categories && selectedIngredient.categories.trim() !== '' && (
                <>
                  <h3>Categories:</h3>
                  <p>{selectedIngredient.categories}</p>
                </>
              )}

              {selectedIngredient && (
                <>
                  <h3>Properties:</h3>
                  <div className={styles.propertiesContainer}>
                    {selectedIngredient.boolean_properties &&
                      Object.entries(selectedIngredient.boolean_properties)
                        .filter(([key, value]) => value)
                        .map(([key]) => (
                          <span key={key} className={`${styles.propertyBubble} ${styles.booleanBubble}`}>
                            {formatPropertyName(key)}
                          </span>
                        ))}

                    {selectedIngredient.integer_properties &&
                      Object.entries(selectedIngredient.integer_properties).map(([key, value]) => (
                        <span
                          key={key}
                          className={`${styles.propertyBubble} ${styles.integerBubble} ${styles[getClassForValue(value)]}`}
                          title={`Rating: ${value}`}
                        >
                          {formatPropertyName(key)}
                        </span>
                      ))}
                  </div>
                </>
              )}

              <h3>Key:</h3>
              <div className={styles.keyContainer}>
                <div className={styles.keyItem}>
                  <span className={`${styles.safetyColorBox} ${styles.safetySafe}`}></span> Safe
                </div>
                <div className={styles.keyItem}>
                  <span className={`${styles.safetyColorBox} ${styles.safetyLightgreen}`}></span> Low Hazard
                </div>
                <div className={styles.keyItem}>
                  <span className={`${styles.safetyColorBox} ${styles.safetyModerate}`}></span> Moderate Hazard
                </div>
                <div className={styles.keyItem}>
                  <span className={`${styles.safetyColorBox} ${styles.safetyWarning}`}></span> Warning
                </div>
                <div className={styles.keyItem}>
                  <span className={`${styles.safetyColorBox} ${styles.safetyUnsafe}`}></span> Risky
                </div>
                <div className={styles.keyItem}>
                  <span className={`${styles.safetyColorBox} ${styles.safetyUnknown}`}></span> Unknown
                </div>
              </div>

              <button onClick={() => setSelectedIngredient(null)} className={styles.closeModalButton}>
                Close
              </button>
            </div>
          </div>
        )}
      </li>
    </>
  );
}

export default SearchModal;
