import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FullScanResultsPage from './FullScanResultsPage'; // The current ResultsPage renamed
import FrontProfileResultsPage from './FrontProfileResultsPage';
import SideProfileResultsPage from './SideProfileResultsPage';
import ComparisonResultsPage from './ComparisonResultsPage';
import CircularProgress from '@mui/material/CircularProgress';
import colors from '../../styles/colors';

function ResultsPageSwitch() {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [scanData, setScanData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const initialData = location.state?.data;

      if (!initialData) {
        console.error('No scan data passed');
        navigate("/scans");
        return;
      }

      setScanData(initialData);
      setLoading(false);
    };

    fetchData();
  }, [location, navigate]);

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress sx={{ color: colors.primary }} size={150} />
      </div>
    );
  }

  // Render the correct results page based on scan type
  switch (scanData.scanType) {
    case 'front':
      return <FrontProfileResultsPage data={scanData} />;
    case 'side':
      return <SideProfileResultsPage data={scanData} />;
    case 'comparison':
      return <ComparisonResultsPage data={scanData} />;
    case 'full':
      return <FullScanResultsPage data={scanData} />;
    default:
      return <FullScanResultsPage data={scanData} />;
  }
}

export default ResultsPageSwitch;
