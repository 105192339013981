import React, { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { FaCopy } from 'react-icons/fa';
import { doc, updateDoc, getDoc, increment } from 'firebase/firestore';
import { auth, firestore } from '../../../firebaseConfig';
import colors from '../../styles/colors';
import { useUser } from './UserContext';
import { fetchStripeProducts, createStripeCheckoutSession } from './stripeService';

const ONE_WEEK_MS = 7 * 24 * 60 * 60 * 1000; // One week in milliseconds

const PurchaseModal = ({ isOpen, onClose }) => {
  const [referralCode, setReferralCode] = useState('');
  const [referralCount, setReferralCount] = useState(0);
  const [showCopiedPopup, setShowCopiedPopup] = useState(false);
  const { setCredits } = useUser();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  // New state variables for free credit feature
  const [lastFreeCreditClaimed, setLastFreeCreditClaimed] = useState(null);
  const [canClaim, setCanClaim] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState('');

  const productMapping = {
    "GR Credits (1)": "1 Credit",
    "GR Credits (10)": "10 Credits",
    "GR Credits (50)": "50 Credits",
  };

  const valueMultiplier = {
    "GR Credits (10)": "4x Value",
    "GR Credits (50)": "10x Value",
  };

  useEffect(() => {
    if (isOpen) {
      const fetchUserDetails = async () => {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(firestore, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setReferralCode(userData.referral_code);
            setReferralCount(userData.num_referrals);
            setLastFreeCreditClaimed(userData.lastFreeCreditClaimed ? userData.lastFreeCreditClaimed.toMillis() : null);
          }
        }
      };

      const fetchProducts = async () => {
        const allProducts = await fetchStripeProducts();
        // Filter out products that contain "Testing" in their name
        const filteredProducts = allProducts.filter(product => !product.name.includes('Testing'));
        setProducts(filteredProducts);
      };

      fetchUserDetails();
      fetchProducts();
    }
  }, [isOpen]);

  // Effect to calculate if user can claim free credit and time remaining
  useEffect(() => {
    if (!isOpen) return;

    const updateClaimStatus = () => {
      if (!lastFreeCreditClaimed) {
        setCanClaim(true);
        setTimeRemaining('');
        return;
      }

      const now = Date.now();
      const nextEligibleTime = lastFreeCreditClaimed + ONE_WEEK_MS;
      if (now >= nextEligibleTime) {
        setCanClaim(true);
        setTimeRemaining('');
      } else {
        setCanClaim(false);
        const remaining = nextEligibleTime - now;
        setTimeRemaining(formatTimeRemaining(remaining));
      }
    };

    updateClaimStatus();

    // Set interval to update every second for live countdown
    const timer = setInterval(() => {
      updateClaimStatus();
    }, 1000); // Update every second

    return () => clearInterval(timer);
  }, [lastFreeCreditClaimed, isOpen]);

  const formatTimeRemaining = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const days = Math.floor(totalSeconds / (24 * 3600));
    const hours = Math.floor((totalSeconds % (24 * 3600)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    let parts = [];
    if (days > 0) parts.push(`${days}d`);
    if (hours > 0 || days > 0) parts.push(`${hours}h`);
    if (minutes > 0 || hours > 0 || days > 0) parts.push(`${minutes}m`);
    parts.push(`${seconds}s`);

    return parts.join(' ');
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(referralCode);
    setShowCopiedPopup(true);
    setTimeout(() => setShowCopiedPopup(false), 500);
  };

  const handlePurchase = async (priceId) => {
    setLoading(true);
    try {
      await createStripeCheckoutSession(priceId, true);
    } catch (error) {
      console.error('Error during purchase: ', error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 5000); // Ensures loading state is shown for at least 5 seconds
    }
  };

  const handleClaimFreeCredit = async () => {
    const user = auth.currentUser;
    if (!user) return;

    const userDocRef = doc(firestore, 'users', user.uid);

    try {
      // Update the user's credits and lastFreeCreditClaimed timestamp
      await updateDoc(userDocRef, {
        credits: increment(1),
        lastFreeCreditClaimed: new Date(),
      });

      // Update local state
      setCredits(prev => prev + 1);
      setLastFreeCreditClaimed(Date.now());
      setCanClaim(false);
      setTimeRemaining(formatTimeRemaining(ONE_WEEK_MS));
    } catch (error) {
      console.error('Error claiming free credit: ', error);
    }
  };

  if (!isOpen) return null;

  return createPortal(
    <div style={overlayStyles}>
      <div style={modalStyles}>
        <button onClick={onClose} style={closeButtonStyles}>x</button>
        <h2 style={titleStyles}>Purchase More Credits</h2>
        <div style={contentStyles}>
          {loading ? (
            <div style={loadingStyles}>Processing...</div>
          ) : (
            products.map(product => (
              <div key={product.priceId} style={tierStyles} onClick={() => handlePurchase(product.priceId)}>
                <span>{productMapping[product.name] || product.name}</span>
                <span>${(product.priceInfo.unit_amount / 100).toFixed(2)}</span>
                {valueMultiplier[product.name] && (
                  <div style={valueBubbleStyles}>{valueMultiplier[product.name]}</div>
                )}
              </div>
            ))
          )}
        </div>

        {/* Free Credit Section */}
        <div style={freeCreditSectionStyles}>
          <h3>Weekly Free Credit</h3>
          {canClaim ? (
            <button onClick={handleClaimFreeCredit} style={claimButtonStyles}>
              Claim Free Credit
            </button>
          ) : (
            <p style={timeRemainingStyles}>
              Next free credit available in: {timeRemaining}
            </p>
          )}
        </div>

        <p style={referralStyles}>or refer a friend with your referral code to get 1 free credit</p>
        <div style={referralSectionStyles}>
          <span style={referralCodeStyles}>{referralCode}</span>
          <button onClick={handleCopy} style={copyButtonStyles}>
            <FaCopy />
          </button>
        </div>
        <p style={referralCountStyles}>{referralCount} referred so far</p>
        {showCopiedPopup && <div style={copiedPopupStyles}>Copied!</div>}
      </div>
    </div>,
    document.body
  );
};

// New Styles for Free Credit Section
const freeCreditSectionStyles = {
  marginTop: '20px',
  textAlign: 'center',
};

const claimButtonStyles = {
  backgroundColor: colors.primary,
  color: colors.white,
  border: 'none',
  borderRadius: '4px',
  padding: '10px 20px',
  cursor: 'pointer',
  fontSize: '16px',
};

const timeRemainingStyles = {
  color: colors.secondary,
  fontSize: '14px',
};

// Existing Styles
const overlayStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const modalStyles = {
  backgroundColor: colors.white,
  padding: '20px',
  borderRadius: '8px',
  width: '400px',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  position: 'relative',
};

const closeButtonStyles = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  backgroundColor: 'transparent',
  border: 'none',
  fontSize: '18px',
  cursor: 'pointer',
};

const titleStyles = {
  margin: '0 0 20px',
  textAlign: 'center',
};

const contentStyles = {
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
  textAlign: 'center',
};

const tierStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '10px 20px',
  border: `1px solid ${colors.primary}`,
  borderRadius: '4px',
  cursor: 'pointer',
  position: 'relative',
};

const valueBubbleStyles = {
  position: 'absolute',
  top: '0px',
  right: '0px',
  backgroundColor: colors.primary,
  color: colors.white,
  padding: '5px 10px',
  borderRadius: '50%',
  fontSize: '12px',
  fontWeight: 'bold',
  transform: 'translate(50%, -50%)',
};

const referralStyles = {
  textAlign: 'center',
  marginTop: '20px',
};

const referralSectionStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  marginTop: '10px',
};

const referralCodeStyles = {
  padding: '5px 10px',
  border: `1px solid ${colors.primary}`,
  borderRadius: '4px',
  backgroundColor: colors.lightGrey,
  fontFamily: 'monospace',
  fontSize: '20px',
};

const copyButtonStyles = {
  backgroundColor: colors.primary,
  color: colors.white,
  border: 'none',
  borderRadius: '4px',
  padding: '5px 10px',
  cursor: 'pointer',
};

const referralCountStyles = {
  textAlign: 'center',
  marginTop: '10px',
};

const copiedPopupStyles = {
  position: 'absolute',
  bottom: '20px',
  left: '50%',
  transform: 'translateX(-50%)',
  backgroundColor: colors.primary,
  color: colors.white,
  padding: '10px 20px',
  borderRadius: '4px',
};

const loadingStyles = {
  textAlign: 'center',
  marginTop: '20px',
  color: colors.primary,
};

export default PurchaseModal;
