import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, getDocs, orderBy } from 'firebase/firestore';
import { auth, firestore, storage } from '../../../firebaseConfig';
import { ref, getDownloadURL } from 'firebase/storage';
import '../../styles/ScansPage.css';

const ScanItem = ({ scan, onClick }) => (
  <li 
    key={scan.id} 
    onClick={() => onClick(scan)} 
    className="scan-item"
  >
    <div className="scan-image-container">
      <img 
        src={scan.repImageUrl} 
        alt={`Scan ${scan.scan_number}`} 
        className="scan-image"
      />
    </div>
    <div className="scan-details">
      <p>Scan {scan.scan_number}</p>
      <p>{new Date(scan.created_at.seconds * 1000).toLocaleString()}</p>
    </div>
  </li>
);

const ScansPage = () => {
  const [scans, setScans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    const fetchScans = async (uid) => {
      try {
        const userScansRef = collection(firestore, 'users', uid, 'scans');
        const scansQuery = query(userScansRef, orderBy('created_at', 'desc'));
        const scanSnapshots = await getDocs(scansQuery);

        const scansList = await Promise.all(
          scanSnapshots.docs.map(async (doc) => {
            const scanData = doc.data();
            const repImageUrl = await getDownloadURL(ref(storage, scanData.repImageUrl));
            return {
              id: doc.id,
              ...scanData,
              repImageUrl,
            };
          })
        );

        if (isMounted) {
          setScans(scansList);
        }
      } catch (err) {
        console.error('Error fetching scans:', err);
        if (isMounted) {
          setError(err);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        fetchScans(user.uid);
      } else {
        if (isMounted) {
          setScans([]);
        }
      }
    });

    return () => {
      isMounted = false;
      unsubscribe();
    };
  }, []);

  const handleScanClick = (scan) => {
    navigate('/results', { state: { data: scan } });
  };

  if (loading) {
    return <div className="scans-page">Loading...</div>;
  }

  if (error) {
    return <div className="scans-page">Error loading scans: {error.message}</div>;
  }

  return (
    <div className="scans-page" style={{ overflowX: 'auto' }}>
      <h1 className="scans-title">Your Scans</h1>
      <ul className="scans-list">
        {scans.map(scan => (
          <ScanItem key={scan.id} scan={scan} onClick={handleScanClick} />
        ))}
      </ul>
    </div>
  );
};

export default ScansPage;
