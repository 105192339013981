export const SleepHygieneRoutine = {
    id: 5,
    name: 'Sleep Hygiene',
    description:
        'Establish habits that promote restful and rejuvenating sleep.',
    canBeShortened: false,
    routines: [
        {
        name: 'Establish a Sleep Schedule',
        description:
            'Go to bed and wake up at the same time every day.',
        steps: [
            'Determine how many hours of sleep you need (usually 7-9 hours).',
            'Set a consistent bedtime and wake-up time.',
            'Stick to this schedule even on weekends.',
        ],
        feedback:
            'Consistency helps regulate your body’s internal clock for better sleep quality.',
        products: {
            beginner: [],
            advanced: [],
            luxury: [],
        },
        },
        {
        name: 'Create a Relaxing Bedtime Routine',
        description:
            'Engage in calming activities before bed.',
        steps: [
            'Dim the lights in your home an hour before bed.',
            'Avoid screens (TV, phone, computer) during this time.',
            'Read a book, meditate, or take a warm bath.',
        ],
        feedback:
            'A relaxing routine signals your body that it’s time to wind down.',
        products: {
            beginner: [],
            advanced: [],
            luxury: [],
        },
        },
        {
        name: 'Optimize Your Sleep Environment',
        description:
            'Make your bedroom conducive to sleep.',
        steps: [
            'Keep the room cool, between 60-67°F (15-19°C).',
            'Ensure your mattress and pillows are comfortable.',
            'Block out noise and light with earplugs and blackout curtains if necessary.',
        ],
        feedback:
            'A comfortable environment promotes uninterrupted sleep.',
        products: {
            beginner: [],
            advanced: [],
            luxury: [],
        },
        },
    ],
}