// src/components/Authorized/components/AuthorizedHeader.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { auth, firestore } from '../../../firebaseConfig';
import fullLogo from '../../../assets/images/logo.png';
import logoMobile from '../../../assets/images/logo_mobile.png';
import creditImage from '../../../assets/images/credit_image.png';
import defaultAvatar from '../../../assets/images/default_avatar.webp'; // Add a default avatar image in your assets
import colors from '../../styles/colors';
import PurchaseModal from './PurchaseModal';
import { useUser } from './UserContext';

function AuthorizedHeader({ user }) {
  const [isPurchaseModalOpen, setPurchaseModalOpen] = useState(false);
  const { credits, setCredits } = useUser();
  const [logoSrc, setLogoSrc] = useState(fullLogo);
  const [navGap, setNavGap] = useState(calculateNavGap(window.innerWidth));
  const [userGap, setUserGap] = useState(calculateUserGap(window.innerWidth));

  function calculateNavGap(width) {
    if (width < 600) return '10px';
    if (width < 1000) return '30px';
    return '60px';
  }

  function calculateUserGap(width) {
    if (width < 600) return '20px';
    if (width < 1000) return '40px';
    return '60px';
  }

  useEffect(() => {
    const updateLogoAndGaps = () => {
      setLogoSrc(window.innerWidth < 1000 ? logoMobile : fullLogo);
      setNavGap(calculateNavGap(window.innerWidth));
      setUserGap(calculateUserGap(window.innerWidth));
    };

    updateLogoAndGaps();
    window.addEventListener('resize', updateLogoAndGaps);

    return () => {
      window.removeEventListener('resize', updateLogoAndGaps);
    };
  }, []);

  useEffect(() => {
    const fetchCredits = async () => {
      if (auth.currentUser) {
        const userDocRef = doc(firestore, 'users', auth.currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setCredits(userDocSnap.data().credits || 0);
        }
      }
    };

    fetchCredits();
  }, [setCredits]);

  const openPurchaseModal = () => {
    setPurchaseModalOpen(true);
  };

  const closePurchaseModal = () => {
    setPurchaseModalOpen(false);
  };

  return (
    <header style={headerStyles}>
      <img
        src={logoSrc}
        alt="Logo"
        style={logoStyles}
        onClick={() => window.location.href = '/upload'}
      />
      <div style={{ ...rightContainerStyles, gap: userGap }}>
        <nav style={{ ...navStyles, gap: navGap }}>
          <Link to="/upload" style={linkStyles}>Upload</Link>
          <Link to="/scans" style={linkStyles}>Your Scans</Link>
          <Link to="/contact" style={linkStyles}>Contact Us</Link>
          <Link to="/profile" style={linkStyles}>Profile</Link> {/* Added Profile Link */}
        </nav>
        <div style={{ ...userContainerStyles, gap: userGap }}>
          <div style={creditContainerStyles} onClick={openPurchaseModal}>
            <img src={creditImage} alt="Credit" style={creditImageStyles} />
            <span style={creditsTextStyles}>{credits}</span>
          </div>
          <Link to="/profile">
            <img
              src={user.photoURL || defaultAvatar}
              alt="Profile"
              style={profileIconStyles}
            />
          </Link>
        </div>
      </div>
      <PurchaseModal isOpen={isPurchaseModalOpen} onClose={closePurchaseModal} />
    </header>
  );
}

const headerStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 20px',
  backgroundColor: colors.background,
  boxSizing: 'border-box',
  height: '60px',
  overflowX: 'auto',
  whiteSpace: 'nowrap',
};

const logoStyles = {
  height: '50px', // Fixed logo height
  cursor: 'pointer'
};

const rightContainerStyles = {
  display: 'flex',
  alignItems: 'center',
};

const navStyles = {
  display: 'flex',
  alignItems: 'center',
};

const linkStyles = {
  textDecoration: 'none',
  color: colors.text,
  fontWeight: 'bold',
  fontSize: '18px',
};

const userContainerStyles = {
  display: 'flex',
  alignItems: 'center',
};

const creditContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer'
};

const creditImageStyles = {
  borderRadius: '50%',
  height: '20px',
};

const creditsTextStyles = {
  color: colors.text,
  fontSize: '16px',
  fontWeight: 'bold',
  marginLeft: '5px'
};

const profileIconStyles = {
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  objectFit: 'cover',
  cursor: 'pointer',
};

export default AuthorizedHeader;
