import React, { useState, useMemo } from 'react';
import ProcessedImage from '../../../../General/ProcessedImage';
import FrontRatiosToggle from '../../FrontRatiosToggle';
import FrontTraitsToggle from '../../FrontTraitsToggle';
import ProgressBar from '../../../../General/ProgressBar';
import colors from '../../../../styles/colors';
import BellCurve from '../../../../General/BellCurve';
import { calculateScorePercentile } from '../../utils';
import UnlockingModal from '../../UnlockingModal';
import LoginModal from '../../../../Unauthorized/components/LoginModal';
import { useNavigate } from 'react-router-dom';
import '../../../../styles/GlowUpContainerComponent.css';

function FrontScanLockedComponent({ data, routines, imagePath, onUnlockConfirm, scanId}) {
  const [visibleFeatures, setVisibleFeatures] = useState({
    pupilDistance: false,
    leftEyeLength: false,
    rightEyeLength: false,
    faceLength: false,
    faceWidth: false,
    noseWidth: false,
    noseHeight: false,
    mouthWidth: false,
    eyeSpacing: false,
    eyeOuterDistance: false,
    leftEyeHeight: false,
    rightEyeHeight: false,
    jawWidth: false,
    chinHeight: false,
    philtrumLength: false,
    midfaceLength: false,
    upperLipHeight: false,
    lowerLipHeight: false,
    eyeSeparationRatio: false,
    eyeAspectRatioLeft: false,
    eyeAspectRatioRight: false,
    jawWidthRatio: false,
    chinPhiltrumRatio: false,
    midfaceRatio: false,
    midfaceCompactness: false,
    mouthNoseHarmony: false,
    eyeSpacingRatio: false,
    noseHeightToWidthRatio: false,
    lipRatio: false,
    facialThirds: false,
  });

  const [toggleView, setToggleView] = useState('traits');
  const [isUnlockingModalOpen, setIsUnlockingModalOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const navigate = useNavigate();

  const toggleFeature = (feature) => {
    setVisibleFeatures((prev) => ({
      ...prev,
      [feature]: !prev[feature],
    }));
  };

  const bestFeatures = useMemo(() => [
    { name: 'Best Feature 1', value: 100, percent: 80 },
    { name: 'Best Feature 2', value: 90, percent: 70 },
    { name: 'Best Feature 3', value: 80, percent: 60 },
  ], []);

  const worstFeatures = useMemo(() => [
    { name: 'Worst Feature 1', value: 30, percent: 20 },
    { name: 'Worst Feature 2', value: 20, percent: 15 },
    { name: 'Worst Feature 3', value: 10, percent: 10 },
  ], []);

  const userScore = useMemo(() => Math.ceil(data.frontResult.score * 10), [data.frontResult.score]);
  const userScorePercentile = useMemo(() => calculateScorePercentile(userScore / 10), [userScore]);

  const handleBlurClick = () => {
    setIsUnlockingModalOpen(true);
    document.body.classList.add('modal-open');
  };

  const closeUnlockingModal = () => {
    setIsUnlockingModalOpen(false);
    document.body.classList.remove('modal-open');
  };

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
    document.body.classList.remove('modal-open');
  };

  const handleExploreRoutines = () => {
    setIsUnlockingModalOpen(true);
    document.body.classList.add('modal-open');
  };


  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
        {imagePath && (
          <div
            style={{
              flex: '1 1 30%',
              marginTop: '30px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div className="glow-up-section" onClick={handleExploreRoutines}>
              <div className="glow-up-card">
                <h2>See your personalized glow up routine</h2>
                <p>Click here to explore routines tailored for you!</p>
                <button className="explore-button">Explore Routines</button>
              </div>
            </div>
            <button
              style={{
                marginBottom: '10px',
                padding: '10px',
                backgroundColor: colors.primary,
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                width: '100%',
                filter: 'blur(3px)',
              }}
              onClick={handleBlurClick}
            >
              Show Best and Worst Features
            </button>
            <div
              style={{
                minHeight: '400px',
                overflowY: 'auto',
                border: '2px solid gray',
                borderRadius: '10px',
                padding: '10px',
                flexGrow: 1,
                filter: 'blur(3px)',
                cursor: 'pointer',
              }}
              onClick={handleBlurClick}
            >
              <>
                <div>
                  <h3>Best Features</h3>
                  {bestFeatures.map((feature, index) => (
                    <div key={index} style={{ padding: '5px', margin: '5px 0' }}>
                      <strong>{feature.name}:</strong> {feature.value}
                      <ProgressBar percent={feature.percent} style={{ width: '100%' }} />
                    </div>
                  ))}
                </div>
                <div>
                  <h3>Worst Features</h3>
                  {worstFeatures.map((feature, index) => (
                    <div key={index} style={{ padding: '5px', margin: '5px 0' }}>
                      <strong>{feature.name}:</strong> {feature.value}
                      <ProgressBar percent={feature.percent} style={{ width: '100%' }} />
                    </div>
                  ))}
                </div>
              </>
            </div>
          </div>
        )}
        {imagePath && (
          <div style={{ flex: '1 1 40%', padding: 30 }}>
            <img
              src={imagePath}
              alt="Processed"
              style={{
                width: '100%',
                border: `12px solid ${colors.primary}`,
                borderRadius: '25px',
              }}
            />
          </div>
        )}
        <div style={{ flex: '1 1 30%', marginLeft: '20px', marginTop: '30px' }}>
          {data && (
            <>
              <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                <strong style={{ fontSize: '24px', color: 'black' }}>
                  Front Profile Score: {userScore}{' '}
                </strong>
                <ProgressBar percent={userScorePercentile} style={{ width: '100%' }} />
                <BellCurve score={userScorePercentile} style={{ width: '100%' }} />
              </div>
              <button
                style={{
                  marginBottom: '10px',
                  padding: '10px',
                  backgroundColor: colors.primary,
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  width: '100%',
                  filter: 'blur(3px)',
                }}
                onClick={handleBlurClick}
              >
                Show Traits
              </button>
              <div
                style={{
                  minHeight: '400px',
                  overflowY: 'auto',
                  padding: '10px',
                  flexGrow: 1,
                  border: '2px solid gray',
                  borderRadius: '10px',
                  filter: 'blur(3px)',
                  cursor: 'pointer',
                }}
                onClick={handleBlurClick}
              >
                {toggleView === 'traits' ? (
                  <FrontTraitsToggle
                    visibleFeatures={visibleFeatures}
                    toggleFeature={toggleFeature}
                  />
                ) : (
                  <FrontRatiosToggle
                    visibleFeatures={visibleFeatures}
                    toggleFeature={toggleFeature}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {(isLoginModalOpen || isUnlockingModalOpen) && <div style={backdropStyles}></div>}
      <UnlockingModal
        isOpen={isUnlockingModalOpen}
        onClose={closeUnlockingModal}
        onConfirm={() => {
          closeUnlockingModal();
          onUnlockConfirm();
        }}
      />
      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={closeLoginModal}
        onSignUpClick={() => {
          closeLoginModal();
          setIsUnlockingModalOpen(true);
          document.body.classList.add('modal-open');
        }}
      />
    </div>
  );
}

const backdropStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 998,
};

export default FrontScanLockedComponent;
