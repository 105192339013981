// BeautyList.js

import { MorningSkinCareRoutine } from './Routines/MorningSkinCare';
import { EveningSkinCareRoutine } from './Routines/EveningSkinCare';
import { WeeklyHairCareRoutine } from './Routines/WeeklyHairCare';
import { ExerciseRoutine } from './Routines/ExerciseRoutine';
import { SleepHygieneRoutine } from './Routines/SleepHygiene';
import { HydrationRoutine } from './Routines/HydrationRoutine';
import { LipHealthRoutine } from './Routines/LipHealthRoutine';
import { DietAndNutritionRoutine } from './Routines/DietAndNutritionRoutine';

// Combine all routines into a single list
export const RoutineList = [
  MorningSkinCareRoutine,
  EveningSkinCareRoutine,
  WeeklyHairCareRoutine,
  ExerciseRoutine,
  SleepHygieneRoutine,
  HydrationRoutine,
  LipHealthRoutine,
  DietAndNutritionRoutine,
];
