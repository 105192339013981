import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../../firebaseConfig';
import '../../styles/UploadPage.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import frontProfilePlaceholder from '../../../assets/images/front_profile_example1.png';
import sideProfilePlaceholder from '../../../assets/images/side_profile_example1.png';

function UploadPage() {
  const [scanType, setScanType] = useState('fullScan'); // Added state for scan type
  const [frontImage, setFrontImage] = useState(null);
  const [sideImage, setSideImage] = useState(null);
  // const [comparisonImage1, setComparisonImage1] = useState(null);
  // const [comparisonImage2, setComparisonImage2] = useState(null);
  const [frontDisplayImage, setFrontDisplayImage] = useState(frontProfilePlaceholder);
  const [sideDisplayImage, setSideDisplayImage] = useState(sideProfilePlaceholder);
  // const [comparisonDisplayImage1, setComparisonDisplayImage1] = useState(frontProfilePlaceholder);
  // const [comparisonDisplayImage2, setComparisonDisplayImage2] = useState(frontProfilePlaceholder);
  const [gender, setGender] = useState('male');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  const handleScanTypeChange = (e) => {
    setScanType(e.target.value);
  };

  const handleFrontImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFrontImage(e.target.files[0]);
      setFrontDisplayImage(URL.createObjectURL(e.target.files[0]));
    } else {
      setFrontImage(null);
      setFrontDisplayImage(frontProfilePlaceholder);
    }
  };

  const handleSideImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSideImage(e.target.files[0]);
      setSideDisplayImage(URL.createObjectURL(e.target.files[0]));
    } else {
      setSideImage(null);
      setSideDisplayImage(sideProfilePlaceholder);
    }
  };

  // const handleComparisonImage1Change = (e) => {
  //   if (e.target.files && e.target.files[0]) {
  //     setComparisonImage1(e.target.files[0]);
  //     setComparisonDisplayImage1(URL.createObjectURL(e.target.files[0]));
  //   } else {
  //     setComparisonImage1(null);
  //     setComparisonDisplayImage1(frontProfilePlaceholder);
  //   }
  // };

  // const handleComparisonImage2Change = (e) => {
  //   if (e.target.files && e.target.files[0]) {
  //     setComparisonImage2(e.target.files[0]);
  //     setComparisonDisplayImage2(URL.createObjectURL(e.target.files[0]));
  //   } else {
  //     setComparisonImage2(null);
  //     setComparisonDisplayImage2(frontProfilePlaceholder);
  //   }
  // };

  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!auth.currentUser) {
      console.error('User not authenticated');
      return;
    }

    setLoading(true);
    setProgress(0);
    const uid = auth.currentUser.uid;
    const frontImageBase64 = frontImage ? await toBase64(frontImage) : null;
    const sideImageBase64 = sideImage ? await toBase64(sideImage) : null;
    // const comparisonImage1Base64 = comparisonImage1 ? await toBase64(comparisonImage1) : null;
    // const comparisonImage2Base64 = comparisonImage2 ? await toBase64(comparisonImage2) : null;

    const incrementProgress = () => {
      let percent = 0;
      const interval = setInterval(() => {
        percent += 1;
        setProgress(percent);
        if (percent >= 100) {
          clearInterval(interval);
        }
      }, 150);
    };

    incrementProgress();

    try {
      let response;

      switch (scanType) {
        case 'frontProfile':
          response = await fetch('https://us-central1-golden-ratio-23459.cloudfunctions.net/api/processFrontProfileScan', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              uid,
              frontImage: frontImageBase64,
              gender,
            }),
          });
          break;

        case 'sideProfile':
          response = await fetch('https://us-central1-golden-ratio-23459.cloudfunctions.net/api/processSideProfileScan', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              uid,
              sideImage: sideImageBase64,
              gender,
            }),
          });
          break;

        case 'fullScan':
          response = await fetch('https://us-central1-golden-ratio-23459.cloudfunctions.net/api/processUserScan', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              uid,
              frontImage: frontImageBase64,
              sideImage: sideImageBase64,
              gender,
            }),
          });
          break;
          
          // case 'comparison':
          //   response = await fetch('https://us-central1-golden-ratio-23459.cloudfunctions.net/api/processComparisonScan', {
          //     method: 'POST',
          //     headers: {
          //       'Content-Type': 'application/json',
          //     },
          //     body: JSON.stringify({
          //       uid,
          //       frontImage1: comparisonImage1Base64,
          //       frontImage2: comparisonImage2Base64,
          //       gender,
          //     }),
          //   });
          //   break;
          

        default:
          throw new Error('Invalid scan type');
      }

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const { scanData } = data;
      console.log(scanData);

      setTimeout(() => {
        setLoading(false);
        navigate('/results', { state: { data: scanData } });
      }, 15000); // Ensure a minimum of 15 seconds loading time
    } catch (error) {
      console.error('Error processing scan:', error);
      setLoading(false);
    }
  };


  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  return (
    <div className="upload-page" style={{ overflowX: 'auto' }}>
      <h1 className="upload-title">
        {loading ? 'Analyzing Your Face' : 'Upload Photos To See Your Facial Attractiveness'}
      </h1>
      {!loading && (
      <div className="scan-type-selector">
        <label>Select Scan Type: </label>
        <select value={scanType} onChange={handleScanTypeChange}>
          <option value="frontProfile">Front Profile</option>
          <option value="sideProfile">Side Profile</option>
          <option value="fullScan">Full Scan</option>
          {/* <option value="comparison">Comparison</option> */}
        </select>
      </div>
    )}
      {loading ? (
        <div className="loading-spinner">
          <CircularProgressbar
            value={progress}
            text={`${progress}%`}
            styles={buildStyles({
              pathColor: '#67CAC7',
              textColor: '#67CAC7',
              trailColor: '#d6d6d6',
              backgroundColor: '#3e98c7',
            })}
          />
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="upload-form">
        {scanType === 'frontProfile' && (
          <div className="upload-section single-upload">
            <h2 className="section-title">Front Profile</h2>
            <div className="upload-box single-upload" onClick={() => document.getElementById('front-input').click()}>
              <img src={frontDisplayImage} alt="Front Profile" className="uploaded-image" />
              {!frontImage && <div className="placeholder-text">Upload Front Profile</div>}
              <input
                id="front-input"
                type="file"
                accept="image/*"
                onChange={handleFrontImageChange}
                className="file-input"
                style={{ display: 'none' }}
              />
            </div>
          </div>
        )}

        {scanType === 'sideProfile' && (
          <div className="upload-section single-upload">
            <h2 className="section-title">Side Profile</h2>
            <div className="upload-box single-upload" onClick={() => document.getElementById('side-input').click()}>
              <img src={sideDisplayImage} alt="Side Profile" className="uploaded-image" />
              {!sideImage && <div className="placeholder-text">Upload Side Profile</div>}
              <input
                id="side-input"
                type="file"
                accept="image/*"
                onChange={handleSideImageChange}
                className="file-input"
                style={{ display: 'none' }}
              />
            </div>
          </div>
        )}

          {scanType === 'fullScan' && (
            <div className="upload-sections">
              <div className="upload-section">
                <h2 className="section-title">Front Profile</h2>
                <div className="upload-box" onClick={() => document.getElementById('front-input').click()}>
                  <img src={frontDisplayImage} alt="Front Profile" className="uploaded-image" />
                  {!frontImage && <div className="placeholder-text">Upload Front Profile</div>}
                  <input
                    id="front-input"
                    type="file"
                    accept="image/*"
                    onChange={handleFrontImageChange}
                    className="file-input"
                    style={{ display: 'none' }}
                  />
                </div>
              </div>
              <div className="upload-section">
                <h2 className="section-title">Side Profile</h2>
                <div className="upload-box" onClick={() => document.getElementById('side-input').click()}>
                  <img src={sideDisplayImage} alt="Side Profile" className="uploaded-image" />
                  {!sideImage && <div className="placeholder-text">Upload Side Profile</div>}
                  <input
                    id="side-input"
                    type="file"
                    accept="image/*"
                    onChange={handleSideImageChange}
                    className="file-input"
                    style={{ display: 'none' }}
                  />
                </div>
              </div>
            </div>
          )}
          {/* {scanType === 'comparison' && (
            <div className="upload-sections">
              <div className="upload-section">
                <h2 className="section-title">Person 1 - Front Profile</h2>
                <div className="upload-box" onClick={() => document.getElementById('comparison-input1').click()}>
                  <img src={comparisonDisplayImage1} alt="Person 1 Front Profile" className="uploaded-image" />
                  {!comparisonImage1 && <div className="placeholder-text">Upload Person 1 Front Profile</div>}
                  <input
                    id="comparison-input1"
                    type="file"
                    accept="image/*"
                    onChange={handleComparisonImage1Change}
                    className="file-input"
                    style={{ display: 'none' }}
                  />
                </div>
              </div>
              <div className="upload-section">
                <h2 className="section-title">Person 2 - Front Profile</h2>
                <div className="upload-box" onClick={() => document.getElementById('comparison-input2').click()}>
                  <img src={comparisonDisplayImage2} alt="Person 2 Front Profile" className="uploaded-image" />
                  {!comparisonImage2 && <div className="placeholder-text">Upload Person 2 Front Profile</div>}
                  <input
                    id="comparison-input2"
                    type="file"
                    accept="image/*"
                    onChange={handleComparisonImage2Change}
                    className="file-input"
                    style={{ display: 'none' }}
                  />
                </div>
              </div>
            </div>
          )} */}
          <div className="gender-section">
            <h2 className="section-title">Gender</h2>
            <select value={gender} onChange={handleGenderChange} className="gender-select" style={{ minWidth: '100px'}}>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>
          <button type="submit" className="upload-button">Upload</button>
        </form>
      )}
    </div>
  );
}

export default UploadPage;
