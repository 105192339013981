import React, { useEffect, useRef, useState } from 'react';
import ProcessedImage from './ProcessedImage';
import colors from '../styles/colors';

function FailedComparisonResultsComponent({ imagePath1, imagePath2 }) {
  const canvasRef1 = useRef(null);
  const canvasRef2 = useRef(null);
  const [imageHeight1, setImageHeight1] = useState(0);
  const [imageHeight2, setImageHeight2] = useState(0);

  useEffect(() => {
    const loadImage = (canvasRef, imagePath, setImageHeight) => {
      if (canvasRef.current && imagePath) {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const image = new Image();
        image.src = imagePath;

        image.onload = () => {
          const screenHalfWidth = window.innerWidth / 2;
          const scale = screenHalfWidth / image.width;

          const calculatedHeight = image.height * scale;
          setImageHeight(calculatedHeight);

          canvas.width = screenHalfWidth;
          canvas.height = calculatedHeight;

          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
        };
      }
    };

    loadImage(canvasRef1, imagePath1, setImageHeight1);
    loadImage(canvasRef2, imagePath2, setImageHeight2);
  }, [imagePath1, imagePath2]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 40 }}>
      <h2 style={{ color: colors.primary, marginBottom: '20px' }}>Comparison Scan Failed</h2>
      <p style={{ marginBottom: '20px', textAlign: 'center' }}>
        Unfortunately, one or both of the front scans did not produce any measurable results. Please try again or check the scans for any issues.
      </p>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
        {imagePath1 && (
          <div style={{ padding: 30 }}>
            <ProcessedImage canvasRef={canvasRef1} style={{ 
              border: `12px solid ${colors.primary}`, 
              borderRadius: '25px',
              maxHeight: '400px'
            }} />
          </div>
        )}
        {imagePath2 && (
          <div style={{ padding: 30 }}>
            <ProcessedImage canvasRef={canvasRef2} style={{ 
              border: `12px solid ${colors.primary}`, 
              borderRadius: '25px',
              maxHeight: '400px'
            }} />
          </div>
        )}
      </div>
    </div>
  );
}

export default FailedComparisonResultsComponent;
