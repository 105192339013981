import AnuaCleanser from './images/ANUA_Cleanser.png'
import CeraVeCleanser from './images/CeraVe_Cleanser.png'
import CosmedixCleanser from './images/Cosmedix_Cleanser.png'
import SturmCleanser from './images/Sturm_Cleanser.png'
import ImFromToner from './images/ImFrom_Toner.png'
import PCAToner from './images/PCA_Toner.png'
import ForteToner from './images/Forte_Toner.png'
import CeraVeSerum from './images/CeraVe_Serum.png'
import VibranceSerum from './images/Vibrance_Serum.png'
import CleDePeauSerum from './images/CleDePeau_Serum.png'
import ElfMoisturizer from './images/Elf_Moisturizer.png'
import ShiseidoMoisturizer from './images/Shiseido_Moisturizer.png'
import CleDePeauMoisturizer from './images/CleDePeau_Moisturizer.png'
import AveenoExfoliator from './images/Aveeno_Exfoliator.png'
import PaulaExfoliator from './images/Paula_Exfoliator.png'
import KyprisExfoliator from './images/Kypris_Exfoliator.png'
import ChrisGuaSha from './images/Chris_GuaSha.png'
import YeamonGuaSha from './images/Yeamon_GuaSha.png'
import LouiseGuaSha from './images/Louise_GuaSha.png'
import JoseonEyeCream from './images/Joseon_EyeCream.png'
import ImageEyeCream from './images/Image_EyeCream.png'
import CleDePeauEyeCream from './images/CleDePeau_EyeCream.png'

export const EveningSkinCareRoutine = {
  id: 2,
  name: 'Evening Skin Care Routine',
  description:
      'Wind down your day with a routine that focuses on repair and rejuvenation.',
  canBeShortened: true,
  routines: [
      {
          name: 'Cleanse',
          includeInShortened: true, // Included in shortened routine
          description:
              'Gently cleanse your face to remove any impurities and makeup from the day.',
          steps: [
              'Splash your face with lukewarm water.',
              'Apply a dime-sized amount of gentle cleanser to your fingertips.',
              'Massage the cleanser onto your face in circular motions for about 30 seconds.',
              'Rinse thoroughly with lukewarm water and pat dry with a clean towel.',
          ],
          feedback:
              'Ensure to remove all traces of makeup and sunscreen to allow your skin to breathe and regenerate overnight.',
          products: {
              beginner: [
                  {
                      id: 101,
                      name: 'ANUA Heartleaf Quercetinol Pore Deep Cleansing Foam',
                      description: 'Affordable cleanser suitable for all skin types.',
                      link: 'https://amzn.to/3XDHSyL',
                      image: AnuaCleanser
                  },
                  {
                      id: 104,
                      name: 'CeraVe SA Facial Cleanser',
                      description: 'Affordable cleanser for daily use.',
                      link: 'https://amzn.to/3zEmQrK',
                      image: CeraVeCleanser
                  },
              ],
              advanced: [
                  {
                      id: 102,
                      name: 'COSMEDIX Hydrating Face Wash',
                      description: 'Mid-range cleanser with added benefits.',
                      link: 'https://amzn.to/4gNCHFo',
                      image: CosmedixCleanser
                  },
              ],
              luxury: [
                  {
                      id: 103,
                      name: 'Dr. Barbara Sturm Anti-Aging Cleansing Cream',
                      description: 'High-end cruelty-free cleanser for a luxurious experience.',
                      link: 'https://amzn.to/47D0cNa',
                      image: SturmCleanser
                  },
              ],
          },
      },
      {
          name: 'Exfoliate (2-3 times a week)',
          includeInShortened: false, // Excluded from shortened routine
          description:
              'Gently exfoliate to remove dead skin cells and promote cell turnover.',
          steps: [
              'Apply a chemical exfoliant (like AHA/BHA) to a cotton pad.',
              'Swipe over your face, avoiding the eye area.',
              'Do not rinse off unless instructed by the product.',
          ],
          feedback:
              'Avoid over-exfoliating as it can lead to irritation and sensitivity.',
          products: {
              beginner: [
                  {
                      id: 401,
                      name: 'Aveeno Positively Radiant Exfoliating Facial Scrub',
                      description: 'An affordable exfoliator that removes dead skin cells and promotes a radiant complexion.',
                      link: 'https://amzn.to/4faQGUb',
                      image: AveenoExfoliator
                  },
              ],
              advanced: [
                  {
                      id: 402,
                      name: "Paula's Choice Skin Perfecting Gel Exfoliant",
                      description: 'A mid-range exfoliant that perfects skin texture and tone.',
                      link: 'https://amzn.to/4dAtmOl',
                      image: PaulaExfoliator
                  },
              ],
              luxury: [
                  {
                      id: 403,
                      name: 'KYPRIS Deep Forest Clay Clarifying Exfoliation Mask',
                      description: 'A luxury exfoliation mask that clarifies and rejuvenates the skin.',
                      link: 'https://amzn.to/47WMSTZ',
                      image: KyprisExfoliator
                  },
              ],
          },
      },
      {
          name: 'Tone',
          includeInShortened: false, // Excluded from shortened routine
          description:
              'Use a toner to balance your skin’s pH levels and prepare it for absorption of subsequent products.',
          steps: [
              'Apply a small amount of toner to a cotton pad.',
              'Gently swipe the cotton pad over your face, avoiding the eye area.',
              'Allow the toner to fully absorb before moving to the next step.',
          ],
          feedback: 'Choose an alcohol-free toner to prevent drying out your skin.',
          products: {
              beginner: [
                  {
                      id: 501,
                      name: "I'm from Rice Toner, Milky Toner for Glowing Skin",
                      description: 'Affordable toner that brightens and hydrates the skin.',
                      link: 'https://amzn.to/3zHWSUt',
                      image: ImFromToner
                  },
              ],
              advanced: [
                  {
                      id: 502,
                      name: 'PCA SKIN Hydrating Toner for Face',
                      description: 'Mid-range toner with hydrating and soothing properties.',
                      link: 'https://amzn.to/3ZMhO7n',
                      image: PCAToner
                  },
              ],
              luxury: [
                  {
                      id: 503,
                      name: 'Irene Forte Helichrysum Toner',
                      description: 'Luxury toner enriched with helichrysum for radiant skin.',
                      link: 'https://amzn.to/3zB7pRr',
                      image: ForteToner
                  },
              ],
          },
      },
      {
          name: 'Apply Serum',
          includeInShortened: true, // Excluded from shortened routine
          description:
              'Serums are concentrated treatments that target specific skin concerns.',
          steps: [
              'Dispense a few drops of serum onto your fingertips.',
              'Gently press the serum into your skin, focusing on problem areas.',
              'Allow it to absorb completely before applying moisturizer.',
          ],
          feedback:
              'For evening routines, consider using treatments like retinol to support skin regeneration overnight.',
          products: {
              beginner: [
                  {
                      id: 201,
                      name: 'CeraVe Retinol Serum',
                      description: 'An affordable retinol serum that helps to reduce the appearance of fine lines and improve skin texture.',
                      link: 'https://amzn.to/4gwgOu5',
                      image: CeraVeSerum
                  },
              ],
              advanced: [
                  {
                      id: 202,
                      name: 'Vibrance Super C Serum',
                      description: 'A mid-range vitamin C serum that brightens the complexion and provides antioxidant protection.',
                      link: 'https://amzn.to/4edRGXf',
                      image: VibranceSerum
                  },
              ],
              luxury: [
                  {
                      id: 203,
                      name: 'Clé de Peau Beauté Concentrated Brightening Serum',
                      description: 'A luxury serum that delivers intense brightness and enhances skin radiance.',
                      link: 'https://amzn.to/3ZE5Rkc',
                      image: CleDePeauSerum
                  },
              ],
          },
      },
      {
          name: 'Moisturize',
          includeInShortened: true, // Included in shortened routine
          description:
              'Hydrate your skin to maintain moisture levels and support overnight repair.',
          steps: [
              'Take a pea-sized amount of moisturizer.',
              'Warm it between your fingers.',
              'Gently massage it into your face and neck using upward strokes.',
          ],
          feedback:
              'Evening moisturizers can be richer to support the skin’s regeneration process overnight.',
          products: {
              beginner: [
                  {
                      id: 304,
                      name: 'e.l.f. Holy Hydration! Face Cream',
                      description: 'An affordable face cream that provides intense hydration and a smooth finish.',
                      link: 'https://amzn.to/4h4EDcK',
                      image: ElfMoisturizer
                  },
              ],
              advanced: [
                  {
                      id: 305,
                      name: 'Shiseido Deep, Intense 24 Hour Moisturizer',
                      description: 'A mid-range moisturizer that offers deep hydration and long-lasting moisture.',
                      link: 'https://amzn.to/3zMbdzb',
                      image: ShiseidoMoisturizer
                  },
              ],
              luxury: [
                  {
                      id: 306,
                      name: 'Clé de Peau Beauté La Crème',
                      description: 'A luxury moisturizer that delivers unparalleled hydration and a radiant complexion.',
                      link: 'https://amzn.to/3NdhBT1',
                      image: CleDePeauMoisturizer
                  },
              ],
          },
      },
      {
          name: 'Eye Cream',
          includeInShortened: false, // Included in shortened routine
          description:
              'Apply an eye cream to hydrate and address concerns like dark circles.',
          steps: [
              'Take a small amount of eye cream on your ring finger.',
              'Gently tap it around the orbital bone, avoiding direct contact with eyes.',
          ],
          feedback:
              'The skin around your eyes is delicate; use gentle motions to avoid tugging.',
          products: {
              beginner: [
                  {
                      id: 701,
                      name: 'Beauty of Joseon Revive Eye Serum',
                      description: 'An affordable eye serum that revitalizes and hydrates the delicate eye area.',
                      link: 'https://amzn.to/3zD2JKN',
                      image: JoseonEyeCream
                  },
              ],
              advanced: [
                  {
                      id: 702,
                      name: 'IMAGE Hydrating Eye Recovery Gel',
                      description: 'A mid-range eye gel that deeply hydrates and reduces puffiness.',
                      link: 'https://amzn.to/3BHgVmm',
                      image: ImageEyeCream
                  },
              ],
              luxury: [
                  {
                      id: 703,
                      name: 'Clé de Peau Beauté Eye Contour Cream Supreme',
                      description: 'A luxury eye contour cream that provides supreme hydration and smooths fine lines.',
                      link: 'https://amzn.to/3zYJQ4V',
                      image: CleDePeauEyeCream
                  },
              ],
          },
      },
      {
          name: 'Gua Sha Massage',
          includeInShortened: false, // Excluded from shortened routine
          description:
              'Incorporate a Gua Sha massage to promote lymphatic drainage and reduce puffiness.',
          steps: [
              'Apply a facial oil or serum to create slip on your skin.',
              'Hold the Gua Sha tool at a 15-degree angle to your face.',
              'Gently glide the tool along your jawline, cheeks, and forehead, using upward strokes.',
              'Repeat each stroke 3-5 times, always working from the center of your face outward.',
          ],
          feedback:
              'Use gentle pressure to avoid bruising. Gua Sha can help relieve tension and enhance blood circulation.',
          products: {
              beginner: [
                  {
                      id: 601,
                      name: 'Rena Chris Natural Jade Stone Guasha',
                      description: 'An affordable jade stone Gua Sha tool for gentle facial massages.',
                      link: 'https://amzn.to/3TXqILn',
                      image: ChrisGuaSha
                  },
              ],
              advanced: [
                  {
                      id: 602,
                      name: 'Yeamon Face Massager, Metal Face Roller Gua Sha',
                      description: 'A mid-range metal Gua Sha roller that enhances facial contours.',
                      link: 'https://amzn.to/3ZRugTc',
                      image: YeamonGuaSha
                  },
              ],
              luxury: [
                  {
                      id: 603,
                      name: 'Georgia Louise Lift + Sculpt Butterfly Stone',
                      description: 'A luxury butterfly-shaped Gua Sha tool for advanced facial sculpting.',
                      link: 'https://amzn.to/3U1mC51',
                      image: LouiseGuaSha
                  },
              ],
          },
      },
  ],
}
