import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LockedFrontResultsComponent from '../components/ScanResultPages/FullScan/LockedFrontResultsComponent';
import LockedSideResultsComponent from '../components/ScanResultPages/FullScan/LockedSideResultsComponent';
import FailedSideResultsComponent from '../../General/FailedSideResultsComponent';
import FailedFrontResultsComponent from '../../General/FailedFrontResultsComponent';
import ProgressBar from '../../General/ProgressBar';
import BellCurve from '../../General/BellCurve';
import colors from '../../styles/colors';
import { calculateScorePercentile } from '../components/utils';
import { collection, query, getDocs, orderBy } from 'firebase/firestore';
import { auth, firestore } from '../../../firebaseConfig';

function LockedResultsPage({ data, onUnlockConfirm, scanId}) {
  const [currentView, setCurrentView] = useState('front');
  const [scans, setScans] = useState([]);
  const navigate = useNavigate();
  const scrollRef = useRef(null);

  const handleFrontClick = () => {
    setCurrentView('front');
  };

  const handleSideClick = () => {
    setCurrentView('side');
    console.log(data.sideResult.score)
  };

  const calculateOverallScore = useCallback(() => {
    if (!data?.frontResult?.score && !data?.sideResult?.score) {
      return 0;
    }

    const frontScore = data?.frontResult?.score || data?.sideResult?.score;
    const sideScore = data?.sideResult?.score || frontScore;

    if (frontScore === 0 || sideScore === 0) {
      return 'Scan failed';
    }

    const higherScore = Math.max(frontScore, sideScore);
    const lowerScore = Math.min(frontScore, sideScore);

    return (higherScore * (2 / 3) + lowerScore * (1 / 3)).toFixed(2);
  }, [data?.frontResult?.score, data?.sideResult?.score]);

  const overallScore = useMemo(() => calculateOverallScore(), [calculateOverallScore]);
  const overallScoreValue = useMemo(() => (overallScore !== 'Scan failed' ? Math.ceil(overallScore * 10) : 0), [overallScore]);
  const overallScorePercentile = useMemo(() => (overallScoreValue !== 0 ? calculateScorePercentile(overallScoreValue / 10) : 0), [overallScoreValue]);

  useEffect(() => {
    const fetchScans = async () => {
      if (auth.currentUser) {
        const userDoc = collection(firestore, 'users', auth.currentUser.uid, 'scans');
        const scansQuery = query(userDoc, orderBy('created_at', 'desc'));
        const scanSnapshots = await getDocs(scansQuery);

        const scansList = scanSnapshots.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        console.log("fetched")
        setScans(scansList);
      }
    };

    fetchScans();
  }, []);

  return (
    <div style={{ background: colors.background, padding: 40 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ 
          marginRight: '20px',
          display: 'flex',
          justifyContent: 'flex-start',
          flex: '1 1 25%',
          height: '150px', 
          width: 'auto', 
          objectFit: 'contain',
        }}>
          <BellCurve score={overallScorePercentile}/>
        </div>
        <div style={{ textAlign: 'center', flex: '1 1 25%' }}>
          <h2>Overall Score: {overallScoreValue}</h2>
          <ProgressBar percent={overallScorePercentile} style={{ width: '100%' }} />
        </div>
        <div style={{ 
          display: 'flex',
          justifyContent: 'flex-end',
          flex: '1 1 25%',
          height: '150px', 
          width: 'auto', 
          objectFit: 'contain',
        }}>
          {currentView === 'front' && data?.sideResult && (
            <img
              src={data.sideResult.image_path}
              alt="Side Profile"
              onClick={handleSideClick}
              style={{ 
                cursor: 'pointer', 
                height: '150px', 
                width: 'auto', 
                border: `8px solid ${colors.primary}`, 
                borderRadius: '12px',
                overflow: 'hidden'
              }}
            />
          )}
          {currentView === 'side' && data?.frontResult && (
            <img
              src={data.frontResult.image_path}
              alt="Front Profile"
              onClick={handleFrontClick}
              style={{ 
                cursor: 'pointer', 
                height: '150px', 
                width: 'auto', 
                border: `8px solid ${colors.primary}`, 
                borderRadius: '12px',
                overflow: 'hidden'
              }}
            />
          )}
          {!data?.frontResult && !data?.sideResult && (
            <div>No front/side profile</div>
          )}
        </div>
      </div>
      <div>
        {currentView === 'front' && data?.frontResult && (
          data.frontResult.score === 0 ? (
            <FailedFrontResultsComponent imagePath={data.frontResult.image_path} />
          ) : (
            <LockedFrontResultsComponent data={data.frontResult} routines={data.routines} imagePath={data.frontResult.image_path} onUnlockConfirm={onUnlockConfirm} scanId={scanId}/>
          )
        )}
        {currentView === 'side' && data?.sideResult && (
          data.sideResult.score === 0 ? (
            <FailedSideResultsComponent imagePath={data.sideResult.image_path} />
          ) : (
            <LockedSideResultsComponent data={data.sideResult} routines={data.routines} imagePath={data.sideResult.image_path} onUnlockConfirm={onUnlockConfirm} scanId={scanId}/>
          )
        )}
      </div>
    </div>
  );
}

export default LockedResultsPage;
