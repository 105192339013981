import React, { useEffect, useRef, useState, useMemo } from 'react';
import ProcessedImage from '../../../../General/ProcessedImage';
import FrontTraitsToggle from '../../FrontTraitsToggle';
import FrontRatiosToggle from '../../FrontRatiosToggle';
import ProgressBar from '../../../../General/ProgressBar';
import colors from '../../../../styles/colors';
import BellCurve from '../../../../General/BellCurve';
import { calculateScorePercentile } from '../../utils';

function ComparisonResultsComponent({ data1, data2, imagePath1, imagePath2 }) {
  const canvasRef1 = useRef(null);
  const canvasRef2 = useRef(null);
  const [visibleFeatures, setVisibleFeatures] = useState({
    pupilDistance: false,
    leftEyeLength: false,
    rightEyeLength: false,
    faceLength: false,
    faceWidth: false,
    noseWidth: false,
    noseHeight: false,
    mouthWidth: false,
    eyeSpacing: false,
    eyeOuterDistance: false,
    leftEyeHeight: false,
    rightEyeHeight: false,
    jawWidth: false,
    chinHeight: false,
    philtrumLength: false,
    midfaceLength: false,
    upperLipHeight: false,
    lowerLipHeight: false,
    eyeSeparationRatio: false,
    eyeAspectRatioLeft: false,
    eyeAspectRatioRight: false,
    jawWidthRatio: false,
    chinPhiltrumRatio: false,
    midfaceRatio: false,
    midfaceCompactness: false,
    mouthNoseHarmony: false,
    eyeSpacingRatio: false,
    noseHeightToWidthRatio: false,
    lipRatio: false,
    facialThirds: false,
    bitemporalWidth: false,
    bitemporalToFaceWidthRatio: false,
    jawFrontalAngle: false,
    chinAngle: false,
    leftCantileTilt: false,
    rightCantileTilt: false,
  });

  const [toggleView, setToggleView] = useState('traits');

  const toggleFeature = (feature) => {
    setVisibleFeatures((prev) => ({
      ...prev,
      [feature]: !prev[feature],
    }));
  };

  const userScore1 = useMemo(() => Math.ceil(data1.score * 10), [data1.score]);
  const userScore2 = useMemo(() => Math.ceil(data2.score * 10), [data2.score]);

  const userScorePercentile1 = useMemo(() => calculateScorePercentile(userScore1 / 10), [userScore1]);
  const userScorePercentile2 = useMemo(() => calculateScorePercentile(userScore2 / 10), [userScore2]);

  const handleToggleFeatureView = () => {
    setToggleView((prev) => (prev === 'traits' ? 'ratios' : 'traits'));
  };

  useEffect(() => {
    const processImage = (canvasRef, imagePath, data) => {
      if (data && canvasRef.current) {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const image = new Image();
        image.src = imagePath;
  
        image.onload = () => {
          const screenHalfWidth = window.innerWidth / 2;
          const scale = screenHalfWidth / image.width;
  
          canvas.width = screenHalfWidth;
          canvas.height = image.height * scale;
  
          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
  
          ctx.save();
          ctx.scale(scale, scale);
  
          const drawLine = (point1, point2) => {
            ctx.beginPath();
            ctx.moveTo(point1[0], point1[1]);
            ctx.lineTo(point2[0], point2[1]);
            ctx.stroke();
          };
  
          ctx.strokeStyle = 'green';
          ctx.lineWidth = 3 / scale;
  
          const drawRatio = (ratio, points) => {
            points.forEach(pair => {
              drawLine(data.measurements_coords[pair[0]], data.measurements_coords[pair[1]]);
            });
          };
  
          const drawHorizontalLines = (points) => {
            const colors = ['red', 'green', 'blue'];
            const labels = ['Forehead Line', 'Brow Line', 'Nose Line', 'Chin Line'];
  
            points.forEach((point, index) => {
              ctx.strokeStyle = colors[index % colors.length];
              ctx.lineWidth = 2 / scale;
              drawLine([0, point[1]], [canvas.width / scale, point[1]]);
              ctx.fillStyle = colors[index % colors.length];
              ctx.fillText(labels[index], 10, point[1] - 10);
            });
          };
  
          // Draw the features based on the `visibleFeatures` state
          if (visibleFeatures.pupilDistance) {
            drawLine(data.measurements_coords["Left Pupil"], data.measurements_coords["Right Pupil"]);
          }
          if (visibleFeatures.leftEyeLength) {
            drawLine(data.measurements_coords["Left Eye Start"], data.measurements_coords["Left Eye End"]);
          }
          if (visibleFeatures.rightEyeLength) {
            drawLine(data.measurements_coords["Right Eye Start"], data.measurements_coords["Right Eye End"]);
          }
          if (visibleFeatures.faceLength) {
            drawLine(data.measurements_coords["Chin Point"], data.measurements_coords["Forehead Point"]);
          }
          if (visibleFeatures.faceWidth) {
            drawLine(data.measurements_coords["Left Face Point"], data.measurements_coords["Right Face Point"]);
          }
          if (visibleFeatures.noseWidth) {
            drawLine(data.measurements_coords["Nose Left Point"], data.measurements_coords["Nose Right Point"]);
          }
          if (visibleFeatures.noseHeight) {
            drawLine(data.measurements_coords["Nose Top Point"], data.measurements_coords["Nose Bottom Point"]);
          }
          if (visibleFeatures.mouthWidth) {
            drawLine(data.measurements_coords["Mouth Left Point"], data.measurements_coords["Mouth Right Point"]);
          }
          if (visibleFeatures.eyeSpacing) {
            drawLine(data.measurements_coords["Left Eye Inner Point"], data.measurements_coords["Right Eye Inner Point"]);
          }
          if (visibleFeatures.eyeOuterDistance) {
            drawLine(data.measurements_coords["Left Eye Outer Point"], data.measurements_coords["Right Eye Outer Point"]);
          }
          if (visibleFeatures.leftEyeHeight) {
            drawLine(data.measurements_coords["Left Eye Height Start"], data.measurements_coords["Left Eye Height End"]);
          }
          if (visibleFeatures.rightEyeHeight) {
            drawLine(data.measurements_coords["Right Eye Height Start"], data.measurements_coords["Right Eye Height End"]);
          }
          if (visibleFeatures.jawWidth) {
            drawLine(data.measurements_coords["Jaw Left Point"], data.measurements_coords["Jaw Right Point"]);
          }
          if (visibleFeatures.chinHeight) {
            drawLine(data.measurements_coords["Chin Height Start"], data.measurements_coords["Chin Height End"]);
          }
          if (visibleFeatures.philtrumLength) {
            drawLine(data.measurements_coords["Philtrum Start"], data.measurements_coords["Philtrum End"]);
          }
          if (visibleFeatures.midfaceLength) {
            drawLine(data.measurements_coords["Midface Start"], data.measurements_coords["Midface End"]);
          }
          if (visibleFeatures.upperLipHeight) {
            drawLine(data.measurements_coords["Upper Lip Top Point"], data.measurements_coords["Upper Lip Bottom Point"]);
          }
          if (visibleFeatures.lowerLipHeight) {
            drawLine(data.measurements_coords["Lower Lip Top Point"], data.measurements_coords["Lower Lip Bottom Point"]);
          }
          if (visibleFeatures.bitemporalWidth) {
            drawLine(data.measurements_coords["Left Temple Point"], data.measurements_coords["Right Temple Point"]);
          }
          if (visibleFeatures.facialThirds) {
            const points = [
              data.measurements_coords["Forehead Point"],
              data.measurements_coords["Brow Line Point"],
              data.measurements_coords["Nose Base Point"],
              data.measurements_coords["Chin Point"]
            ];
            drawHorizontalLines(points);
          }
  
          // Ratios
          if (visibleFeatures.eyeSeparationRatio) {
            drawRatio('eyeSeparationRatio', [["Left Pupil", "Right Pupil"], ["Left Face Point", "Right Face Point"]]);
          }
          if (visibleFeatures.eyeAspectRatioLeft) {
            drawRatio('eyeAspectRatioLeft', [["Left Eye Start", "Left Eye End"], ["Left Eye Height Start", "Left Eye Height End"]]);
          }
          if (visibleFeatures.eyeAspectRatioRight) {
            drawRatio('eyeAspectRatioRight', [["Right Eye Start", "Right Eye End"], ["Right Eye Height Start", "Right Eye Height End"]]);
          }
          if (visibleFeatures.jawWidthRatio) {
            drawRatio('jawWidthRatio', [["Jaw Left Point", "Jaw Right Point"], ["Left Face Point", "Right Face Point"]]);
          }
          if (visibleFeatures.chinPhiltrumRatio) {
            drawRatio('chinPhiltrumRatio', [["Chin Height Start", "Chin Height End"], ["Philtrum Start", "Philtrum End"]]);
          }
          if (visibleFeatures.midfaceRatio) {
            drawRatio('midfaceRatio', [["Left Pupil", "Right Pupil"], ["Midface Start", "Midface End"]]);
          }
          if (visibleFeatures.midfaceCompactness) {
            drawRatio('midfaceCompactness', [["Left Face Point", "Right Face Point"], ["Midface Start", "Midface End"]]);
          }
          if (visibleFeatures.mouthNoseHarmony) {
            drawRatio('mouthNoseHarmony', [["Mouth Left Point", "Mouth Right Point"], ["Nose Left Point", "Nose Right Point"]]);
          }
          if (visibleFeatures.eyeSpacingRatio) {
            drawRatio('eyeSpacingRatio', [["Left Eye Inner Point", "Right Eye Inner Point"], ["Left Eye Outer Point", "Right Eye Outer Point"]]);
          }
          if (visibleFeatures.noseHeightToWidthRatio) {
            drawRatio('noseHeightToWidthRatio', [["Nose Top Point", "Nose Bottom Point"], ["Nose Left Point", "Nose Right Point"]]);
          }
          if (visibleFeatures.lipRatio) {
            const adjustUpperLip = ([x, y]) => [x + 3, y];
            const adjustLowerLip = ([x, y]) => [x - 3, y];
            drawLine(adjustUpperLip(data.measurements_coords["Upper Lip Top Point"]), adjustUpperLip(data.measurements_coords["Upper Lip Bottom Point"]));
            drawLine(adjustLowerLip(data.measurements_coords["Lower Lip Top Point"]), adjustLowerLip(data.measurements_coords["Lower Lip Bottom Point"]));
          }
          if (visibleFeatures.bitemporalToFaceWidthRatio) {
            drawRatio('bitemporalToFaceWidthRatio', [["Left Temple Point", "Right Temple Point"], ["Left Face Point", "Right Face Point"]]);
          }
  
          // New features
          if (visibleFeatures.jawFrontalAngle) {
            drawLine(data.measurements_coords["Jaw Left Point1"], data.measurements_coords["Jaw Left Point2"]);
            drawLine(data.measurements_coords["Jaw Right Point1"], data.measurements_coords["Jaw Right Point2"]);
          }
          if (visibleFeatures.chinAngle) {
            drawLine(data.measurements_coords["Chin Point"], data.measurements_coords["Chin End Left"]);
            drawLine(data.measurements_coords["Chin Point"], data.measurements_coords["Chin End Right"]);
          }
          if (visibleFeatures.leftCantileTilt) {
            drawLine(data.measurements_coords["Left Eye Outer Point"], data.measurements_coords["Left Eye Inner Point"]);
          }
          if (visibleFeatures.rightCantileTilt) {
            drawLine(data.measurements_coords["Right Eye Outer Point"], data.measurements_coords["Right Eye Inner Point"]);
          }
        };
      }
    };
  
    // Process the first and second images with their respective data
    processImage(canvasRef1, imagePath1, data1);
    processImage(canvasRef2, imagePath2, data2);
  }, [imagePath1, imagePath2, data1, data2, visibleFeatures]);
  

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
      <div style={{ flex: '1 1 40%', marginTop: '30px', display: 'flex', flexDirection: 'column' }}>
        <div style={{ textAlign: 'center', marginBottom: '10px' }}>
          <strong style={{ fontSize: '24px', color: 'black' }}>Score: {userScore1}</strong>
          <ProgressBar percent={userScorePercentile1} style={{ width: '100%' }} />
          <BellCurve score={userScorePercentile1} style={{ width: '100%' }} />
        </div>
        <div style={{ padding: 30 }}>
          <ProcessedImage canvasRef={canvasRef1} style={{ 
            border: `12px solid ${colors.primary}`, 
            borderRadius: '25px'
          }} />
        </div>
      </div>
      <div style={{ 
        flex: '1 1 20%', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'flex-start', 
        alignItems: 'center',
        marginTop: '30px' 
      }}>
        <div style={{ height: '400px', backgroundColor: 'transparent', width: '100%' }}></div> {/* Transparent box */}
        <div style={{
          border: `2px solid ${colors.primary}`, 
          padding: '20px', 
          borderRadius: '10px', 
          width: '100%', 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center' 
        }}>
          <button onClick={handleToggleFeatureView} style={{ 
            padding: '10px', 
            backgroundColor: colors.primary, 
            color: 'white', 
            border: 'none', 
            borderRadius: '5px', 
            cursor: 'pointer', 
            marginBottom: '20px', 
            width: '200px' 
          }}>
            {toggleView === 'traits' ? 'Show Ratios' : 'Show Traits'}
          </button>
          {toggleView === 'traits' ? (
            <FrontTraitsToggle visibleFeatures={visibleFeatures} toggleFeature={toggleFeature} />
          ) : (
            <FrontRatiosToggle visibleFeatures={visibleFeatures} toggleFeature={toggleFeature} />
          )}
        </div>
      </div>
      <div style={{ flex: '1 1 40%', marginTop: '30px', display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
        <div style={{ textAlign: 'center', marginBottom: '10px' }}>
          <strong style={{ fontSize: '24px', color: 'black' }}>Score: {userScore2}</strong>
          <ProgressBar percent={userScorePercentile2} style={{ width: '100%' }} />
          <BellCurve score={userScorePercentile2} style={{ width: '100%' }} />
        </div>
        <div style={{ padding: 30 }}>
          <ProcessedImage canvasRef={canvasRef2} style={{ 
            border: `12px solid ${colors.primary}`, 
            borderRadius: '25px'
          }} />
        </div>
      </div>
    </div>
  );
}

export default ComparisonResultsComponent;
