import EtercityScale from './images/Etercity_Scale.png'

export const DietAndNutritionRoutine = {
    id: 8,
    name: 'Diet and Nutrition Routine',
    description: 'Ensure you get the necessary vitamins and minerals through a balanced diet and tracking your intake.',
    canBeShortened: false,
    routines: [
        {
            name: 'Balanced Meal Planning (Daily)',
            description: 'Incorporate a variety of foods to meet your daily vitamin and mineral needs.',
            steps: [
                'Include a variety of vegetables, fruits, lean proteins, whole grains, and healthy fats in your meals.',
                'Ensure your meals include sources of key vitamins and minerals such as calcium, iron, vitamin D, vitamin C, and B vitamins.',
                'Focus on eating fresh, whole foods and avoid highly processed options when possible.',
            ],
            feedback: 'A balanced diet rich in whole foods helps you naturally meet your vitamin and mineral requirements without the need for supplements.',
            products: {
            },
        },
        {
            name: 'Track Your Vitamin and Mineral Intake',
            description: 'Use apps or tools to track your daily intake of essential vitamins and minerals.',
            steps: [
                'Record your meals and snacks using a nutrition tracking app.',
                'Pay attention to key vitamins and minerals like Vitamin C, Vitamin D, Iron, Magnesium, and Calcium.',
                'Ensure you are hitting your recommended daily values based on your age, gender, and lifestyle.',
            ],
            feedback: 'Tracking your intake can help identify any nutritional gaps and make adjustments as needed.',
            products: {
                general: [ // Added General category
                    {
                        id: 1202, // Unique ID for the product
                        name: 'Etecktiy Smart Food Kitchen Scale',
                        description: 'Accurately measure your food portions with the Etecktiy Smart Food Kitchen Scale, featuring a sleek design and easy-to-read display.',
                        image: EtercityScale,
                        link: 'https://amzn.to/3NgrhMS',
                    },
                ],
            },
        },
    ],
};
