import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/UploadPage.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import frontProfilePlaceholder from '../../../assets/images/front_profile_example1.png';
import sideProfilePlaceholder from '../../../assets/images/side_profile_example1.png';
import LoginModal from '../components/LoginModal';
import SignUpModal from '../components/SignUpModal';

function UnauthorizedUploadPage() {
  // Changed default scanType to 'frontProfile'
  const [scanType, setScanType] = useState('frontProfile');
  const [frontImage, setFrontImage] = useState(null);
  const [sideImage, setSideImage] = useState(null);
  const [frontDisplayImage, setFrontDisplayImage] = useState(frontProfilePlaceholder);
  const [sideDisplayImage, setSideDisplayImage] = useState(sideProfilePlaceholder);
  const [gender, setGender] = useState('male');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);

  const handleScanTypeChange = (e) => {
    const selectedScanType = e.target.value;
    // If the user selects 'fullScan' or 'sideProfile', show login modal
    if (selectedScanType === 'fullScan' || selectedScanType === 'sideProfile') {
      setIsLoginModalOpen(true);
      // Reset scanType to 'frontProfile' to prevent change
      setScanType('frontProfile');
    } else {
      setScanType(selectedScanType);
    }
  };

  const handleFrontImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFrontImage(e.target.files[0]);
      setFrontDisplayImage(URL.createObjectURL(e.target.files[0]));
    } else {
      setFrontImage(null);
      setFrontDisplayImage(frontProfilePlaceholder);
    }
  };

  const handleSideImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSideImage(e.target.files[0]);
      setSideDisplayImage(URL.createObjectURL(e.target.files[0]));
    } else {
      setSideImage(null);
      setSideDisplayImage(sideProfilePlaceholder);
    }
  };

  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setProgress(0);
    const frontImageBase64 = frontImage ? await toBase64(frontImage) : null;
    const sideImageBase64 = sideImage ? await toBase64(sideImage) : null;

    const incrementProgress = () => {
      let percent = 0;
      const interval = setInterval(() => {
        percent += 1;
        setProgress(percent);
        if (percent >= 100) {
          clearInterval(interval);
        }
      }, 150);
    };

    incrementProgress();

    try {
      let endpoint = '';
      let bodyData = {};

      // Adjusted to call the appropriate endpoint based on scanType
      if (scanType === 'frontProfile') {
        endpoint =
          'https://us-central1-golden-ratio-23459.cloudfunctions.net/api/processUnauthorizedFrontScan';
        bodyData = {
          frontImage: frontImageBase64,
          gender,
        };
      } else {
        // Should not reach here as other scan types require login
        throw new Error('Invalid scan type');
      }

      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const { scanData } = data;
      console.log(scanData);

      setTimeout(() => {
        setLoading(false);
        navigate('/results', {
          state: { data: scanData, frontImage: frontImageBase64 },
        });
      }, 15000); // Ensure a minimum of 15 seconds loading time
    } catch (error) {
      console.error('Error processing scan:', error);
      setLoading(false);
    }
  };

  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  // Modal control functions
  const openLoginModal = () => {
    setIsLoginModalOpen(true);
    document.body.classList.add('modal-open');
  };

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
    document.body.classList.remove('modal-open');
  };

  const openSignUpModal = () => {
    setIsSignUpModalOpen(true);
    document.body.classList.add('modal-open');
  };

  const closeSignUpModal = () => {
    setIsSignUpModalOpen(false);
    document.body.classList.remove('modal-open');
  };

  return (
    <>
      <div className="upload-page" style={{ overflowX: 'auto' }}>
        <h1 className="upload-title">
          {loading
            ? 'Analyzing Your Face'
            : 'Upload Photos To See Your Facial Attractiveness'}
        </h1>
        {!loading && (
          <div className="scan-type-selector">
            <label>Select Scan Type: </label>
            <select value={scanType} onChange={handleScanTypeChange}>
              {/* Scan type options */}
              <option value="frontProfile">Front Profile</option>
              <option value="sideProfile">Side Profile</option>
              <option value="fullScan">Full Scan</option>
            </select>
          </div>
        )}
        {loading ? (
          <div className="loading-spinner">
            <CircularProgressbar
              value={progress}
              text={`${progress}%`}
              styles={buildStyles({
                pathColor: '#67CAC7',
                textColor: '#67CAC7',
                trailColor: '#d6d6d6',
                backgroundColor: '#3e98c7',
              })}
            />
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="upload-form">
            {scanType === 'frontProfile' && (
              <div className="upload-section single-upload">
                <h2 className="section-title">Front Profile</h2>
                <div
                  className="upload-box single-upload"
                  onClick={() => document.getElementById('front-input').click()}
                >
                  <img
                    src={frontDisplayImage}
                    alt="Front Profile"
                    className="uploaded-image"
                  />
                  {!frontImage && <div className="placeholder-text">Upload Front Profile</div>}
                  <input
                    id="front-input"
                    type="file"
                    accept="image/*"
                    onChange={handleFrontImageChange}
                    className="file-input"
                    style={{ display: 'none' }}
                  />
                </div>
              </div>
            )}

            {/* We don't need to show side profile or full scan options since they require login */}
            {/* Gender selection */}
            <div className="gender-section">
              <h2 className="section-title">Gender</h2>
              <select
                value={gender}
                onChange={handleGenderChange}
                className="gender-select"
                style={{ minWidth: '100px' }}
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>
            <button type="submit" className="upload-button" disabled={!frontImage}>
              Upload
            </button>
          </form>
        )}
      </div>
      {/* Include the backdrop when modals are open */}
      {isLoginModalOpen || isSignUpModalOpen ? <div style={backdropStyles}></div> : null}
      {/* Login Modal */}
      <LoginModal
        isOpen={isLoginModalOpen}
        onClose={closeLoginModal}
        onSignUpClick={() => {
          closeLoginModal();
          openSignUpModal();
        }}
      />
      {/* Sign Up Modal */}
      <SignUpModal
        isOpen={isSignUpModalOpen}
        onClose={closeSignUpModal}
        onLoginClick={() => {
          closeSignUpModal();
          openLoginModal();
        }}
      />
    </>
  );
}

const backdropStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 998, // Ensure it is below the modal
};

export default UnauthorizedUploadPage;
