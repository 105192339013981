import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import UnlockedResultsPage from './UnlockedResultsPage';
import LockedResultsPage from './LockedResultsPage';
import UnlockingModal from '../components/UnlockingModal';
import PurchaseModal from '../components/PurchaseModal';
import CircularProgress from '@mui/material/CircularProgress';
import colors from '../../styles/colors';
import { doc, getDoc, updateDoc, collection, query, getDocs, orderBy } from 'firebase/firestore';
import { firestore, auth, storage } from '../../../firebaseConfig';
import { ref, getDownloadURL } from 'firebase/storage';
import { useUser } from '../components/UserContext';
import { getFunctions, httpsCallable } from 'firebase/functions';

function FullScanResultsPage() {
  const [isLocked, setIsLocked] = useState(true);
  const [fullScanData, setFullScanData] = useState(null);
  const [isUnlockingModalOpen, setIsUnlockingModalOpen] = useState(false);
  const [isPurchaseModalOpen, setIsPurchaseModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isUnlocking, setIsUnlocking] = useState(false); // Add state for unlocking spinner
  const location = useLocation();
  const initialScanData = location.state?.data;
  const [scanDataHelper, setScanDataHelper] = useState(initialScanData);
  const [scanData, setScanData] = useState(initialScanData);
  const { credits, setCredits } = useUser();
  const [scans, setScans] = useState([]);
  const scrollRef = useRef(null);
  const navigate = useNavigate();
  const functions = getFunctions();

  const preloadImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = resolve;
      img.onerror = reject;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          console.error('User not authenticated');
          return;
        }

        // Fetch scans if not already passed in
        if (!location.state?.scans) {
          const userDoc = collection(firestore, 'users', user.uid, 'scans');
          const scansQuery = query(userDoc, orderBy('created_at', 'desc'));
          const scanSnapshots = await getDocs(scansQuery);

          const scansList = await Promise.all(
            scanSnapshots.docs.map(async (doc) => {
              const scanData = doc.data();
              const repImageUrl = await getDownloadURL(ref(storage, scanData.repImageUrl));
              return {
                id: doc.id,
                ...scanData,
                repImageUrl,
              };
            })
          );

          setScans(scansList);

        // Check if the current scanDataHelper needs to be updated due to "unauthorized" paths
        if (scanDataHelper.frontResult.image_path.includes('unauthorized') || 
            scanDataHelper.sideResult.image_path.includes('unauthorized')) {
            navigate("/scans");
        }

        } else {
          setScans(location.state.scans);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching scans:', error);
      }
    };

    fetchData();
    console.log("fetched");
  }, []); // Only run once on mount

  useEffect(() => {
    const fetchScanData = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          console.error('User not authenticated');
          return;
        }
        let updatedScanData = { ...scanDataHelper };

        if (updatedScanData.unlocked && updatedScanData.full_scan_id) {
          const fullScanDocRef = doc(firestore, 'users', user.uid, 'scans', updatedScanData.scan_id, 'fullScan', updatedScanData.full_scan_id);
          const fullScanDocSnapshot = await getDoc(fullScanDocRef);

          if (fullScanDocSnapshot.exists()) {
            const fullScanData = fullScanDocSnapshot.data();

            const frontImageUrl = await getDownloadURL(ref(storage, fullScanData.frontResult.image_path));
            const sideImageUrl = await getDownloadURL(ref(storage, fullScanData.sideResult.image_path));

            await Promise.all([preloadImage(frontImageUrl), preloadImage(sideImageUrl)]);

            fullScanData.frontResult.image_path = frontImageUrl;
            fullScanData.sideResult.image_path = sideImageUrl;

            updatedScanData = { ...updatedScanData, ...fullScanData };
            setFullScanData(fullScanData);
            setIsLocked(false);
          } else {
            console.error('Full scan data not found');
          }
        } else {
          const frontImageUrl = await getDownloadURL(ref(storage, updatedScanData.frontResult.image_path));
          const sideImageUrl = await getDownloadURL(ref(storage, updatedScanData.sideResult.image_path));

          await Promise.all([preloadImage(frontImageUrl), preloadImage(sideImageUrl)]);

          updatedScanData.frontResult.image_path = frontImageUrl;
          updatedScanData.sideResult.image_path = sideImageUrl;

          setIsLocked(true);
        }

        setScanData(updatedScanData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching scan data:', error);
      }
    };

    fetchScanData();
    console.log("fetched2");
  }, [scanDataHelper]);

  const handleToggle = () => {
    setIsUnlockingModalOpen(true);
  };

  const handleUnlockConfirm = async () => {
    if (credits <= 0) {
      setIsPurchaseModalOpen(true);
      return;
    }

    setIsUnlocking(true); // Show unlocking spinner
    try {
      const user = auth.currentUser;

      if (user && scanData.full_scan_id) {
        const unlockFullScan = httpsCallable(functions, "unlockFullScanFunction");
        const result = await unlockFullScan({scanData});

        const { fullScanData, updatedCredits } = result.data;

        const frontImageUrl = await getDownloadURL(ref(storage, fullScanData.frontResult.image_path));
        const sideImageUrl = await getDownloadURL(ref(storage, fullScanData.sideResult.image_path));
        fullScanData.frontResult.image_path = frontImageUrl;
        fullScanData.sideResult.image_path = sideImageUrl;

        setFullScanData(fullScanData);
        setScanData({ ...scanData, unlocked: true });
        setScanDataHelper({ ...scanData, unlocked: true });
        setCredits(updatedCredits);
        setIsLocked(false);

        setScans(prevScans => prevScans.map(scan => 
          scan.id === scanData.scan_id ? { ...scan, unlocked: true } : scan
        ));
      } else {
        console.error('User not authenticated or full_scan_id not found');
      }
    } catch (error) {
      console.error('Error unlocking full scan:', error);
    }

    setTimeout(() => {
      setIsUnlocking(false); // Hide unlocking spinner after half a second
    }, 500);
    setIsUnlockingModalOpen(false);
  };

  const handleUnlockingModalClose = () => {
    setIsUnlockingModalOpen(false);
  };

  const handleScanClick = (scan) => {
    setLoading(true); // Show loading spinner
  
    setTimeout(() => {
      setScanDataHelper(scan);
      window.scrollTo({ top: 0, behavior: 'smooth' }); // Jump to the top of the page
    }, 1000);
    console.log(scan)
  };
  const formatDate = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    const today = new Date();
    if (date.toDateString() === today.toDateString()) {
      return date.toLocaleTimeString();
    } else {
      return date.toLocaleDateString();
    }
  };

  const scrollLeft = () => {
    scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
  };

  const scrollRight = () => {
    scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
  };

  return (
    <div style={{ background: colors.background, padding: 40, minWidth: '1100px', overflowX: 'auto'  }}>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress sx={{ color: colors.primary }} size={150} />
        </div>
      ) : (
        <>
          {isUnlocking ? ( // Show unlocking spinner if unlocking is in progress
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
              <CircularProgress sx={{ color: colors.primary }} size={150} />
            </div>
          ) : (
            <>
              {isLocked && (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <button
                    onClick={handleToggle}
                    style={{
                      background: colors.primary,
                      border: 'none',
                      borderRadius: '12px',
                      padding: '10px 20px',
                      color: '#fff',
                      cursor: 'pointer',
                    }}
                  >
                    Show Full Results
                  </button>
                </div>
              )}
              <div style={{ marginTop: '20px' }}>
                {isLocked ? (
                  <LockedResultsPage data={scanData} onUnlockConfirm={handleUnlockConfirm} scanId={scanData.scan_id}/>
                ) : (
                  <UnlockedResultsPage data={fullScanData} scans={scans} scanId={scanData.scan_id}/>
                )}
              </div>
              {isUnlockingModalOpen && <div style={backdropStyles}></div>}
              <UnlockingModal 
                isOpen={isUnlockingModalOpen} 
                onClose={handleUnlockingModalClose} 
                onConfirm={handleUnlockConfirm} 
              />
              <PurchaseModal 
                isOpen={isPurchaseModalOpen} 
                onClose={() => setIsPurchaseModalOpen(false)} 
              />
              <div style={{ position: 'relative', marginTop: '20px', paddingBottom: '20px', border: `2px solid ${colors.primary}`, borderRadius: '12px', overflow: 'hidden' }}>
                <button onClick={scrollLeft} style={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)', zIndex: 1, background: colors.primary, border: 'none', borderRadius: '50%', width: '40px', height: '40px', cursor: 'pointer' }}>
                  &lt;
                </button>
                <div ref={scrollRef} style={{ display: 'flex', overflowX: 'scroll', padding: '10px', scrollBehavior: 'smooth' }}>
                  {scans.map(scan => (
                    <div key={scan.id} onClick={() => handleScanClick(scan)} style={{ cursor: 'pointer', flex: '0 0 auto', marginRight: '10px' }}>
                      <div style={{ 
                        width: '100px', 
                        height: '100px', 
                        overflow: 'hidden', 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        marginBottom: '10px' 
                      }}>
                        <img 
                          src={scan.repImageUrl} 
                          alt={`Scan ${scan.scan_number}`} 
                          style={{ 
                            width: 'auto', 
                            height: '100%' 
                          }} 
                        />
                      </div>
                      <div style={{ textAlign: 'center' }}>
                        <p>Scan {scan.scan_number}</p>
                        <p>{formatDate(scan.created_at)}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <button onClick={scrollRight} style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)', zIndex: 1, background: colors.primary, border: 'none', borderRadius: '50%', width: '40px', height: '40px', cursor: 'pointer' }}>
                  &gt;
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

const backdropStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 998,
};

export default FullScanResultsPage;
