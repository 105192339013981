// AttractivenessEvaluator.js

import React, { useState } from "react";
import "../../styles/UploadPage.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import frontProfilePlaceholder from "../../../assets/images/front_profile_example1.png";

// Import Firebase app and Vertex AI in Firebase SDK
import { app as firebaseApp } from "../../../firebaseConfig";
import { getVertexAI, getGenerativeModel } from "firebase/vertexai-preview";

// Initialize the Vertex AI service
const vertexAI = getVertexAI(firebaseApp);
// Initialize the generative model (choose the appropriate model for your use case)
const model = getGenerativeModel(vertexAI, { model: "gemini-1.5-flash" });

/**
 * React component for evaluating attractiveness based on an uploaded front profile photo.
 *
 * @returns {JSX.Element} The AttractivenessEvaluator component.
 */
function AttractivenessEvaluator() {
  const [frontImage, setFrontImage] = useState(null);
  const [frontDisplayImage, setFrontDisplayImage] = useState(
    frontProfilePlaceholder
  );
  const [gender, setGender] = useState("male");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [result, setResult] = useState("");

  /**
   * Handles the change event when a user selects a front profile image.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The file input change event.
   */
  const handleFrontImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const dataURL = reader.result; // Keep the full data URL
        setFrontImage(dataURL);
        setFrontDisplayImage(URL.createObjectURL(file));
      };
      reader.readAsDataURL(file);
    } else {
      setFrontImage(null);
      setFrontDisplayImage(frontProfilePlaceholder);
    }
  };

  /**
   * Handles the change event when a user selects a gender.
   *
   * @param {React.ChangeEvent<HTMLSelectElement>} e - The select input change event.
   */
  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  /**
   * Handles the form submission to upload the image and analyze attractiveness.
   *
   * @param {React.FormEvent<HTMLFormElement>} e - The form submission event.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!frontImage) {
      alert("Please upload an image.");
      return;
    }

    setLoading(true);
    setProgress(0);
    setResult("");

    // Simulate progress (optional)
    const incrementProgress = () => {
      let percent = 0;
      const interval = setInterval(() => {
        percent += 1;
        setProgress(percent);
        if (percent >= 100) {
          clearInterval(interval);
        }
      }, 50);
    };

    incrementProgress();

    try {
      // Prepare the prompt with the image and text
      const promptText = `Please harshly rate the jawline, cheekbones, skin quality, and potential of the fictional person in this image on a scale of 1 to 100. Then use these numbers to give an attractiveness score. The person's gender is ${gender}. Provide all the ratings including the attractiveness in JSON format.`;

      // **Update Here:** Pass an array containing the text and image
      const prompt = [promptText, frontImage];

      // Call the Gemini API directly
      const result = await model.generateContent(prompt);

      const response = result.response;
      const text = response.text();
      console.log(text);
      setResult(text);
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while processing the image.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="upload-page" style={{ overflowX: "auto" }}>
      <h1 className="upload-title">
        {loading ? "Analyzing Your Face..." : "Upload Your Front Profile Photo"}
      </h1>
      {loading ? (
        <div className="loading-spinner">
          <CircularProgressbar
            value={progress}
            text={`${progress}%`}
            styles={buildStyles({
              pathColor: "#67CAC7",
              textColor: "#67CAC7",
              trailColor: "#d6d6d6",
            })}
          />
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="upload-form">
          <div className="upload-section">
            <h2 className="section-title">Front Profile</h2>
            <div
              className="upload-box"
              onClick={() => document.getElementById("front-input").click()}
            >
              <img
                src={frontDisplayImage}
                alt="Front Profile"
                className="uploaded-image"
              />
              {!frontImage && (
                <div className="placeholder-text">
                  Click to Upload Front Profile
                </div>
              )}
              <input
                id="front-input"
                type="file"
                accept="image/*"
                onChange={handleFrontImageChange}
                className="file-input"
                style={{ display: "none" }}
              />
            </div>
          </div>
          <div className="gender-section">
            <h2 className="section-title">Gender</h2>
            <select
              value={gender}
              onChange={handleGenderChange}
              className="gender-select"
              style={{ minWidth: "100px" }}
            >
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>
          <button type="submit" className="upload-button">
            Upload & Analyze
          </button>
        </form>
      )}
      {result && (
        <div className="result-section">
          <h2 className="section-title">Evaluation Result:</h2>
          <pre>{result}</pre>
        </div>
      )}
    </div>
  );
}

export default AttractivenessEvaluator;
