// src/components/AuthListener.js
import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebaseConfig';

const AuthListener = ({ setUser }) => {
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      // Removed navigate('/upload') to prevent overriding user's intended route
    });

    return () => unsubscribe();
  }, [setUser]);

  return null;
};

export default AuthListener;
