// src/components/Authorized/screens/ProfilePage.js

import React, { useEffect, useState } from 'react';
import { signOut, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, updateDoc, arrayRemove } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { auth, firestore } from '../../../firebaseConfig';
import { useUser } from '../components/UserContext';
import SearchModal from '../components/SearchModal';
import styles from '../../styles/ProfilePage.module.css';

// Import the ingredient data
import cleanedIngredients from '../../../assets/data/cleaned_ingredients.json';

// Utility functions
const formatPropertyName = (str) => {
  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const getClassForValue = (value) => {
  return `color${value}`;
};

const getSafetyClass = (decision) => {
  if (decision.includes('Safe') && !decision.includes('hazard')) {
    return 'safetySafe';
  } else if (decision.includes('Safe - Low hazard')) {
    return 'safetyLightgreen';
  } else if (
    decision.includes('Safe - Moderate hazard') ||
    decision.includes('Fair') ||
    decision.includes('Limited')
  ) {
    return 'safetyModerate';
  } else if (decision.includes('Moderate hazard')) {
    return 'safetyWarning';
  } else if (decision.includes('Unsafe') || decision.includes('High hazard')) {
    return 'safetyUnsafe';
  } else {
    return 'safetyUnknown';
  }
};

// Create a mapping from ingredient titles to ingredient data
const titleToIngredientMap = new Map();
cleanedIngredients.forEach((ingredient) => {
  titleToIngredientMap.set(ingredient.title.toLowerCase(), ingredient);
});

// Ingredient Modal Component
function IngredientModal({ ingredient, onClose }) {
  if (!ingredient) return null;

  return (
    <div className={styles.ingredientModal} onClick={onClose} role="dialog" aria-modal="true">
      <div className={styles.ingredientContent} onClick={(e) => e.stopPropagation()}>
        <h2>{ingredient.title}</h2>
        {ingredient.ewg && (
          <>
            <h3>EWG Decision:</h3>
            <p>{ingredient.ewg.decision}</p>
          </>
        )}
        {ingredient.categories && ingredient.categories.trim() !== '' && (
          <>
            <h3>Categories:</h3>
            <p>{ingredient.categories}</p>
          </>
        )}

        {ingredient && (
          <>
            <h3>Properties:</h3>
            <div className={styles.propertiesContainer}>
              {ingredient.boolean_properties &&
                Object.entries(ingredient.boolean_properties)
                  .filter(([key, value]) => value)
                  .map(([key]) => (
                    <span key={key} className={`${styles.propertyBubble} ${styles.booleanBubble}`}>
                      {formatPropertyName(key)}
                    </span>
                  ))}

              {ingredient.integer_properties &&
                Object.entries(ingredient.integer_properties).map(([key, value]) => (
                  <span
                    key={key}
                    className={`${styles.propertyBubble} ${styles.integerBubble} ${styles[getClassForValue(value)]}`}
                    title={`Rating: ${value}`}
                  >
                    {formatPropertyName(key)}
                  </span>
                ))}
            </div>
          </>
        )}

        <h3>Key:</h3>
        <div className={styles.keyContainer}>
          <div className={styles.keyItem}>
            <span className={`${styles.safetyColorBox} ${styles.safetySafe}`}></span> Safe
          </div>
          <div className={styles.keyItem}>
            <span className={`${styles.safetyColorBox} ${styles.safetyLightgreen}`}></span> Low Hazard
          </div>
          <div className={styles.keyItem}>
            <span className={`${styles.safetyColorBox} ${styles.safetyModerate}`}></span> Moderate Hazard
          </div>
          <div className={styles.keyItem}>
            <span className={`${styles.safetyColorBox} ${styles.safetyWarning}`}></span> Warning
          </div>
          <div className={styles.keyItem}>
            <span className={`${styles.safetyColorBox} ${styles.safetyUnsafe}`}></span> Risky
          </div>
          <div className={styles.keyItem}>
            <span className={`${styles.safetyColorBox} ${styles.safetyUnknown}`}></span> Unknown
          </div>
        </div>

        <button onClick={onClose} className={styles.closeModalButton}>
          Close
        </button>
      </div>
    </div>
  );
}

// Confirmation Modal Component
function ConfirmRemoveModal({ product, onConfirm, onCancel }) {
  if (!product) return null;

  return (
    <div className={styles.confirmModal} onClick={onCancel} role="dialog" aria-modal="true">
      <div className={styles.confirmContent} onClick={(e) => e.stopPropagation()}>
        <h2>Confirm Removal</h2>
        <p>
          Are you sure you want to remove <strong>{product.title}</strong> from your beauty routine?
        </p>
        <div className={styles.confirmButtons}>
          <button onClick={onConfirm} className={styles.confirmButton}>
            Yes, Remove
          </button>
          <button onClick={onCancel} className={styles.cancelButton}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

function ProfilePage() {
  const navigate = useNavigate();
  const { credits, setCredits } = useUser();
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [savedProducts, setSavedProducts] = useState([]);
  const [user, setUser] = useState(null); // Manage user locally

  // State for managing flipped products, selected ingredient, and product removal
  const [flippedProducts, setFlippedProducts] = useState({});
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  const [productToRemove, setProductToRemove] = useState(null); // For confirmation modal

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        console.log('Fetching user data for user:', currentUser.uid);
        try {
          // Fetch credits from the main user document
          const userDocRef = doc(firestore, 'users', currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            console.log('User main data fetched:', userData);
            setCredits(userData.credits || 0);
          } else {
            console.log('No user document found.');
            setCredits(0);
          }

          // Fetch saved products from the UserData subcollection
          const userDataDocRef = doc(
            firestore,
            'users',
            currentUser.uid,
            'UserData',
            'currentBeautyRoutine'
          );
          const userDataDocSnap = await getDoc(userDataDocRef);
          if (userDataDocSnap.exists()) {
            const userData = userDataDocSnap.data();
            console.log('User beauty routine data fetched:', userData);
            setSavedProducts(userData.products || []);
          } else {
            console.log('No UserData document found.');
            setSavedProducts([]);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          setCredits(0);
          setSavedProducts([]);
        }
      } else {
        console.log('No user is authenticated.');
        setUser(null);
        setCredits(0);
        setSavedProducts([]);
      }
    });

    return () => unsubscribe();
  }, [setCredits]);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      console.log('User signed out.');
      navigate('/upload');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const openSearchModal = () => {
    console.log('Opening Search Modal.');
    setIsSearchModalOpen(true);
  };

  const closeSearchModal = () => {
    console.log('Closing Search Modal.');
    setIsSearchModalOpen(false);
    // Refresh the saved products and credits after closing the modal
    if (auth.currentUser) {
      console.log('Refreshing saved products and credits after closing modal.');
      const currentUser = auth.currentUser;

      // Fetch credits from the main user document
      const userDocRef = doc(firestore, 'users', currentUser.uid);
      const userDataDocRef = doc(
        firestore,
        'users',
        currentUser.uid,
        'UserData',
        'currentBeautyRoutine'
      );

      // Use Promise.all to fetch both documents in parallel
      Promise.all([getDoc(userDocRef), getDoc(userDataDocRef)])
        .then(([userDocSnap, userDataDocSnap]) => {
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            console.log('Refreshed user main data:', userData);
            setCredits(userData.credits || 0);
          } else {
            console.log('No user document found on refresh.');
            setCredits(0);
          }

          if (userDataDocSnap.exists()) {
            const userData = userDataDocSnap.data();
            console.log('Refreshed user beauty routine data:', userData);
            setSavedProducts(userData.products || []);
          } else {
            console.log('No UserData document found on refresh.');
            setSavedProducts([]);
          }
        })
        .catch((error) => {
          console.error('Error refreshing user data:', error);
        });
    }
  };

  // Function to toggle card flip
  const toggleCardFlip = (productId) => {
    setFlippedProducts((prev) => ({
      ...prev,
      [productId]: !prev[productId],
    }));
  };

  // Function to handle ingredient click
  const handleIngredientClick = (ingredientName) => {
    const ingredientData = titleToIngredientMap.get(ingredientName.toLowerCase());
    if (ingredientData) {
      setSelectedIngredient(ingredientData);
    } else {
      alert('Ingredient details not available.');
    }
  };

  // Function to remove a product from the routine
  const removeProduct = async (productId) => {
    if (!user) return;

    const product = savedProducts.find((p) => p.id === productId);
    if (!product) return;

    try {
      const userDataDocRef = doc(
        firestore,
        'users',
        user.uid,
        'UserData',
        'currentBeautyRoutine'
      );

      // Update Firestore by removing the product with the given ID
      await updateDoc(userDataDocRef, {
        products: arrayRemove(product),
      });

      // Update local state
      setSavedProducts((prevProducts) =>
        prevProducts.filter((p) => p.id !== productId)
      );

      console.log(`Product with ID ${productId} removed from routine.`);
    } catch (error) {
      console.error('Error removing product:', error);
      alert('Failed to remove product. Please try again.');
    }
  };

  // If user is not authenticated, prompt to sign in or show a message
  if (!user) {
    return (
      <div className={styles.profileContainer}>
        <p className={styles.noSavedProducts}>No user is authenticated. Please sign in.</p>
      </div>
    );
  }

  return (
    <div className={styles.profileContainer}>
      <h1 className={styles.header}>Your Profile</h1>

      <div className={styles.section}>
        <h2 className={styles.sectionHeader}>Current Beauty Routine</h2>
        {savedProducts.length > 0 ? (
          <div className={styles.productScrollContainer}>
            {/* Gradient overlays to indicate scrollability */}
            <div className={styles.gradientLeft}></div>
            <div className={styles.productScroll}>
              {savedProducts.map((product) => (
                <div
                  key={product.id}
                  className={`${styles.productCard} ${
                    flippedProducts[product.id] ? styles.flipped : ''
                  }`}
                  onClick={(e) => {
                    // Prevent toggling when clicking the Remove button
                    if (!e.target.closest(`.${styles.removeButton}`)) {
                      toggleCardFlip(product.id);
                    }
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      toggleCardFlip(product.id);
                    }
                  }}
                  tabIndex="0"
                  role="button"
                  aria-expanded={!!flippedProducts[product.id]}
                  aria-label={`Toggle ingredients for ${product.title}`}
                >
                  <div className={styles.cardInner}>
                    {/* Front Side */}
                    <div className={styles.cardFront}>
                      {product.primary_image && (
                        <img
                          src={product.primary_image}
                          alt={product.title}
                          className={styles.productImage}
                          onError={(e) => {
                            console.log(`Image failed to load for product: ${product.title}`);
                            e.target.style.display = 'none';
                          }}
                        />
                      )}
                      <div className={styles.productInfo}>
                        <h3 className={`${styles.productTitle} ${styles.title}`}>
                          {product.title}
                        </h3>
                        <p className={styles.productBrand}>
                          <strong>Brand:</strong> {product.brand}
                        </p>
                      </div>
                    </div>
                    {/* Back Side */}
                    <div className={styles.cardBack}>
                      <div className={styles.backContent}>
                        <h3 className={styles.fullProductTitle}>{product.title}</h3>
                        <p className={styles.productBrand}>
                          <strong>Brand:</strong> {product.brand}
                        </p>
                        <div className={styles.ingredientsSection}>
                          <strong>Ingredients:</strong>
                          {product.matched_ingredients ? (
                            <div className={styles.ingredientsList}>
                              {product.matched_ingredients
                                .split(',')
                                .map((ingredient) => ingredient.trim())
                                .map((ingredientName, index) => {
                                  const ingredientData = titleToIngredientMap.get(
                                    ingredientName.toLowerCase()
                                  );
                                  return (
                                    <button
                                      key={index}
                                      className={`${styles.ingredientButton} ${
                                        styles[
                                          ingredientData
                                            ? getSafetyClass(ingredientData.ewg?.decision || '')
                                            : 'safetyUnknown'
                                        ]
                                      }`}
                                      onClick={() => handleIngredientClick(ingredientName)}
                                      aria-label={`View details for ${ingredientName}`}
                                    >
                                      {ingredientName}
                                    </button>
                                  );
                                })}
                            </div>
                          ) : (
                            <p>Not available.</p>
                          )}
                        </div>
                        {/* Buy More Button */}
                        {product.asin && (
                          <a
                            href={`http://www.amazon.com/dp/${product.asin}/ref=nosim?tag=goldenratio07-20`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.buyMoreButton}
                            aria-label={`Buy more of ${product.title} on Amazon`}
                          >
                            Buy More
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                  <button
                    className={styles.removeButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      setProductToRemove(product);
                    }}
                    aria-label={`Remove ${product.title} from your routine`}
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>
            {/* <div className={styles.gradientRight}></div> */} {/* Removed this line */}
          </div>
        ) : (
          <p className={styles.noSavedProducts}>
            Your beauty routine is empty. Click below to add products.
          </p>
        )}
        <button className={styles.addProductButton} onClick={openSearchModal}>
          Add Products
        </button>
      </div>

      <div className={styles.creditsSection}>
        <span className={styles.creditsText}>Credits: {credits}</span>
      </div>

      <button onClick={handleSignOut} className={styles.signOutButton}>
        Sign Out
      </button>

      {isSearchModalOpen && <SearchModal isOpen={isSearchModalOpen} onClose={closeSearchModal} />}

      {/* Ingredient Modal */}
      {selectedIngredient && (
        <IngredientModal
          ingredient={selectedIngredient}
          onClose={() => setSelectedIngredient(null)}
        />
      )}

      {/* Confirmation Modal */}
      {productToRemove && (
        <ConfirmRemoveModal
          product={productToRemove}
          onConfirm={() => {
            removeProduct(productToRemove.id);
            setProductToRemove(null);
          }}
          onCancel={() => setProductToRemove(null)}
        />
      )}
    </div>
  );
}

export default ProfilePage;
