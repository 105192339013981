import AnuaCleanser from './images/ANUA_Cleanser.png'
import CeraVeCleanser from './images/CeraVe_Cleanser.png'
import CosmedixCleanser from './images/Cosmedix_Cleanser.png'
import SturmCleanser from './images/Sturm_Cleanser.png'
import ImFromToner from './images/ImFrom_Toner.png'
import PCAToner from './images/PCA_Toner.png'
import ForteToner from './images/Forte_Toner.png'
import CeraVeSerum from './images/CeraVe_Serum.png'
import VibranceSerum from './images/Vibrance_Serum.png'
import CleDePeauSerum from './images/CleDePeau_Serum.png'
import ElfMoisturizer from './images/Elf_Moisturizer.png'
import ShiseidoMoisturizer from './images/Shiseido_Moisturizer.png'
import CleDePeauMoisturizer from './images/CleDePeau_Moisturizer.png'
import IUNIKSunscreen from './images/IUNIK_Sunscreen.png'
import EltaMDSunscreen from './images/EltaMD_Sunscreen.png'
import MoursiSunscreen from './images/Moursi_Sunscreen.png'

export const MorningSkinCareRoutine = {
    id: 1,
    name: 'Morning Skin Care Routine',
    description:
        'Start your day with a refreshing skin care routine that preps your skin for the day ahead.',
    canBeShortened: true,
    routines: [
        {
            name: 'Cleanse',
            includeInShortened: true, // Included in shortened routine
            description:
                'Gently cleanse your face to remove any impurities that accumulated overnight.',
            steps: [
                'Splash your face with lukewarm water.',
                'Apply a dime-sized amount of gentle cleanser to your fingertips.',
                'Massage the cleanser onto your face in circular motions for about 30 seconds.',
                'Rinse thoroughly with lukewarm water and pat dry with a clean towel.',
            ],
            feedback:
                'Avoid using hot water as it can strip your skin of natural oils. Be gentle to prevent irritation.',
            products: {
                beginner: [
                    {
                        id: 101,
                        name: 'ANUA Heartleaf Quercetinol Pore Deep Cleansing Foam',
                        description: 'Affordable cleanser suitable for all skin types.',
                        link: 'https://amzn.to/3XDHSyL',
                        image: AnuaCleanser
                    },
                    {
                        id: 104,
                        name: 'CeraVe SA Facial Cleanser',
                        description: 'Affordable cleanser for daily use.',
                        link: 'https://amzn.to/3zEmQrK',
                        image: CeraVeCleanser
                    },
                ],
                advanced: [
                    {
                        id: 102,
                        name: 'COSMEDIX Hydrating Face Wash',
                        description: 'Mid-range cleanser with added benefits.',
                        link: 'https://amzn.to/4gNCHFo',
                        image: CosmedixCleanser
                    },
                ],
                luxury: [
                    {
                        id: 103,
                        name: 'Dr. Barbara Sturm Anti-Aging Cleansing Cream',
                        description: 'High-end cruelty-free cleanser for a luxurious experience.',
                        link: 'https://amzn.to/47D0cNa',
                        image: SturmCleanser
                    },
                ],
            },
        },
        {
            name: 'Tone',
            includeInShortened: false, // Excluded from shortened routine
            description:
                'Use a toner to balance your skin’s pH levels and prepare it for absorption of subsequent products.',
            steps: [
                'Apply a small amount of toner to a cotton pad.',
                'Gently swipe the cotton pad over your face, avoiding the eye area.',
                'Allow the toner to fully absorb before moving to the next step.',
            ],
            feedback: 'Choose an alcohol-free toner to prevent drying out your skin.',
            products: {
                beginner: [
                    {
                        id: 501,
                        name: "I'm from Rice Toner, Milky Toner for Glowing Skin",
                        description: 'Affordable toner that brightens and hydrates the skin.',
                        link: 'https://amzn.to/3zHWSUt',
                        image: ImFromToner
                    },
                ],
                advanced: [
                    {
                        id: 502,
                        name: 'PCA SKIN Hydrating Toner for Face',
                        description: 'Mid-range toner with hydrating and soothing properties.',
                        link: 'https://amzn.to/3ZMhO7n',
                        image: PCAToner
                    },
                ],
                luxury: [
                    {
                        id: 503,
                        name: 'Irene Forte Helichrysum Toner',
                        description: 'Luxury toner enriched with helichrysum for radiant skin.',
                        link: 'https://amzn.to/3zB7pRr',
                        image: ForteToner
                    },
                ],
            },
        },
        {
            name: 'Apply Serum',
            includeInShortened: false, // Excluded from shortened routine
            description:
                'Serums are concentrated treatments that target specific skin concerns.',
            steps: [
                'Dispense a few drops of serum onto your fingertips.',
                'Gently press the serum into your skin, focusing on problem areas.',
                'Allow it to absorb completely before applying moisturizer.',
            ],
            feedback:
                'For morning routines, consider using a vitamin C serum to protect against environmental damage.',
            products: {
                beginner: [
                    {
                        id: 201,
                        name: 'CeraVe Retinol Serum',
                        description: 'An affordable retinol serum that helps to reduce the appearance of fine lines and improve skin texture.',
                        link: 'https://amzn.to/4gwgOu5',
                        image: CeraVeSerum
                    },
                ],
                advanced: [
                    {
                        id: 202,
                        name: 'Vibriance Super C Serum',
                        description: 'A mid-range vitamin C serum that brightens the complexion and provides antioxidant protection.',
                        link: 'https://amzn.to/4edRGXf',
                        image: VibranceSerum
                    },
                ],
                luxury: [
                    {
                        id: 203,
                        name: 'Clé de Peau Beauté Concentrated Brightening Serum',
                        description: 'A luxury serum that delivers intense brightness and enhances skin radiance.',
                        link: 'https://amzn.to/3ZE5Rkc',
                        image: CleDePeauSerum
                    },
                ],
            },
        },
        {
            name: 'Moisturize',
            includeInShortened: true, // Included in shortened routine
            description:
                'Hydrate your skin to maintain moisture levels and create a smooth base for makeup.',
            steps: [
                'Take a pea-sized amount of moisturizer.',
                'Warm it between your fingers.',
                'Gently massage it into your face and neck using upward strokes.',
            ],
            feedback:
                'Even if you have oily skin, moisturizing is essential to prevent your skin from overproducing oil.',
            products: {
                beginner: [
                    {
                        id: 304,
                        name: 'e.l.f. Holy Hydration! Face Cream',
                        description: 'An affordable face cream that provides intense hydration and a smooth finish.',
                        link: 'https://amzn.to/4h4EDcK',
                        image: ElfMoisturizer
                    },
                ],
                advanced: [
                    {
                        id: 305,
                        name: 'Shiseido Deep, Intense 24 Hour Moisturizer',
                        description: 'A mid-range moisturizer that offers deep hydration and long-lasting moisture.',
                        link: 'https://amzn.to/3zMbdzb',
                        image: ShiseidoMoisturizer
                    },
                ],
                luxury: [
                    {
                        id: 306,
                        name: 'Clé de Peau Beauté La Crème',
                        description: 'A luxury moisturizer that delivers unparalleled hydration and a radiant complexion.',
                        link: 'https://amzn.to/3NdhBT1',
                        image: CleDePeauMoisturizer
                    },
                ],
            },
        },
        {
            name: 'Sun Protection',
            includeInShortened: true, // Included in shortened routine
            description: 'Apply sunscreen to protect your skin from harmful UV rays.',
            steps: [
                'Apply a generous amount of broad-spectrum SPF 30 or higher sunscreen.',
                'Cover all exposed areas, including your neck and ears.',
                'Reapply every two hours if you’re spending time outdoors.',
            ],
            feedback:
                'Sun protection is crucial to prevent premature aging and reduce the risk of skin cancer.',
            products: {
                beginner: [
                    {
                        id: 401,
                        name: 'IUNIK Centella Calming Moisture Daily Sunscreen',
                        description: 'An affordable daily sunscreen that calms and moisturizes the skin while providing effective UV protection.',
                        link: 'https://amzn.to/4dGeYUx',
                        image: IUNIKSunscreen
                    },
                ],
                advanced: [
                    {
                        id: 402,
                        name: 'EltaMD UV Clear Tinted Face Sunscreen',
                        description: 'A mid-range tinted sunscreen that offers clear protection with a lightweight, breathable finish.',
                        link: 'https://amzn.to/3BHitgc',
                        image: EltaMDSunscreen
                    },
                ],
                luxury: [
                    {
                        id: 403,
                        name: 'Mila Moursi Broad Spectrum Sunscreen',
                        description: 'A luxury broad-spectrum sunscreen that provides superior protection with a luxurious, non-greasy formula.',
                        link: 'https://amzn.to/3zOFBck',
                        image: MoursiSunscreen
                    },
                ],
            },
        },
    ],
}
